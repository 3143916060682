// -------------------[ API - related actions ]--------------------
export const API_ERROR_CREATE = "API_ERROR_CREATE";
export const API_ERROR_CLEAR = "API_ERROR_CLEAR";
export const RESET_ERROR_REDUCER = "RESET_ERROR_REDUCER";

// ------------------------[ auth actions ]------------------------
export const LOGIN = "LOGIN";
export const AUTH_ATTEMPT_RESPONSE = "AUTH_ATTEMPT_RESPONSE";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const UPDATE_USER_LOGIN_INFO = "UPDATE_USER_LOGIN_INFO";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const UPDATE_LOGIN = "UPDATE_LOGIN";
export const DOES_EMAIL_EXIST = "DOES_EMAIL_EXIST";
export const EMAIL_RESET_PASSWORD_LINK = "EMAIL_RESET_PASSWORD_LINK";

export const SEND_EMAIL_VERIFICTION = "SEND_EMAIL_VERIFICATION";
export const SEND_EMAIL_VERIFICTION_ONE_TIME_PASSWORD = "SEND_EMAIL_VERIFICATION_ONE_TIME_PASSWORD";
export const SEND_EMAIL_VERIFICATION_SUCCESS = "SEND_EMAIL_VERIFICATION_SUCCESS";

export const VERIFY_EMAIL = "VERIFY_EMAIL";
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS";

export const EMAIL_LOGIN = "EMAIL_LOGIN";
export const EMAIL_LOGIN_SUCCESS = "EMAIL_LOGIN_SUCCESS";

export const GET_LANDING_PAGE = "GET_LANDING_PAGE";
export const GET_LANDING_PAGE_SUCCESS = "GET_LANDING_PAGE_SUCCESS";

// ----------------[ external application actions ]----------------
export const VALIDATE_EXTRACT_JWTHB_TOKEN = "VALIDATE_EXTRACT_JWTHB_TOKEN";
export const VALIDATE_EXTRACT_JWTHB_TOKEN_SUCCESS = "VALIDATE_EXTRACT_JWTHB_TOKEN_SUCCESS";
export const GET_EXTERNAL_CUSTOMER_APP_INFO = "GET_EXTERNAL_CUSTOMER_APP_INFO";
export const GET_EXTERNAL_CUSTOMER_APP_INFO_SUCCESS = "GET_EXTERNAL_CUSTOMER_APP_INFO_SUCCESS";
export const START_CREATEACCOUNT = "START_CREATEACCOUNT";
export const SAVE_CREATE_ACCOUNT_SUCCESS = "SAVE_CREATE_ACCOUNT_SUCCESS";
export const START_VERIFYPHONE = "START_VERIFYPHONE";
export const VERIFY_PHONE_SUCCESS = "VERIFY_PHONE_SUCCESS";
export const SAVE_EXTERNAL_LEAD_QUERY_PARAMS = "SAVE_EXTERNAL_LEAD_QUERY_PARAMS";

// ----------------[ organic application actions ]-----------------
export const RESET_APPLICATION_REDUCER = "RESET_APPLICATION_REDUCER";

export const SAVE_APPLICATION_SUCCESS = "SAVE_APPLICATION_SUCCESS";
export const UPDATE_PHONE_NUMBER = "UPDATE_PHONE_NUMBER";
export const UPDATE_APPLICATION = "UPDATE_APPLICATION";

export const IS_MKWEB_RESET_PASSWORD_TOKEN_VALID = "IS_MKWEB_RESET_PASSWORD_TOKEN_VALID";
export const IS_MKWEB_RESET_PASSWORD_TOKEN_VALID_SUCCESS = "IS_MKWEB_RESET_PASSWORD_TOKEN_VALID_SUCCESS";
export const IS_MKWEB_INVALID_RESET_PASSWORD_TOKEN = "IS_INVALID_RESET_PASSWORD_TOKEN";
export const IS_MKWEB_INVALID_EMAIL_VERIFICATION_TOKEN = "IS_INVALID_EMAIL_VERIFICATION_TOKEN";

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PASSWORD_WITHOUT_TOKEN = "UPDATE_PASSWORD_WITHOUT_TOKEN";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";

export const UPDATE_USERAPPINFO = "UPDATE_USERAPPINFO";
export const UPDATE_USERAPPMISC = "UPDATE_USERAPPMISC";
export const UPDATE_USERLOANAPPPROGRESS = "UPDATE_USERLOANAPPPROGRESS";


export const GET_STATE_SELECTION_ORGANIZATION_ID = "GET_STATE_SELECTION_ORGANIZATION_ID";
export const GET_STATE_SELECTION_ORGANIZATION_ID_SUCCESS = "GET_STATE_SELECTION_ORGANIZATION_ID_SUCCESS";

export const START_APPLICATION = "START_APPLICATION";
export const SAVE_STATE_SELECTION = "SAVE_STATE_SELECTION";
export const START_APPLICATION_SUCCESS = "START_APPLICATION_SUCCESS";
export const START_APPLICATION_ERROR = "START_APPLICATION_ERROR";

export const IS_UNIQUE_EMAIL_ADDRESS = "IS_UNIQUE_EMAIL_ADDRESS";
export const CHECK_EMAIL_STATUS_AND_SUBMIT_STATE_INFO = "CHECK_EMAIL_STATUS_AND_SUBMIT_STATE_INFO";
export const IS_UNIQUE_SSN_GLOBALLY = "IS_UNIQUE_SSN_GLOBALLY";

export const START_PERSONALINFO = "START_PERSONALINFO";
export const START_ADDRESSINFO = "START_ADDRESSINFO";
export const START_LIVINGSITUATION = "START_LIVINGSITUATION";
export const START_EMPLOYMENTINFO = "START_EMPLOYMENTINFO";
export const START_PAYMENTSCHEDULE = "START_PAYMENTSCHEDULE";
export const START_PREAPPROVED = "START_PREAPPROVED";

// Hotbox pre check request happens after payment schedule save.
export const START_HB_PRE_STAGE_CALL_SUCCESS = "START_HB_PRE_STAGE_CALL_SUCCESS";
// Hotbox post check request happens after banking info save
export const START_HB_POST_STAGE_CALL_SUCCESS = "START_HB_POST_STAGE_CALL_SUCCESS";
export const START_BANKINGINFO = "START_BANKINGINFO";
export const START_PRODUCT_CHANGE_POPUP_ACKNOWLEDGEMENT = "START_PRODUCT_CHANGE_POPUP_ACKNOWLEDGEMENT";
export const START_PRODUCT_CHANGE_POPUP_ACKNOWLEDGEMENT_SUCCESS = "START_PRODUCT_CHANGE_POPUP_ACKNOWLEDGEMENT_SUCCESS";

export const START_CREATEPASSWORD = "START_CREATEPASSWORD";
export const START_CREATEPASSWORD_SUCCESS = "START_CREATEPASSWORD_SUCCESS";
export const START_CREATEPASSWORD_ERROR = "START_CREATEPASSWORD_ERROR";

export const SAVE_URL_PARAMS = "SAVE_URL_PARAMS";

export const UPDATE_PAYMENT_SCHEDULE_CHOICES = "UPDATE_PAYMENT_SCHEDULE_CHOICES";

export const SAVE_SUB_ID_TRACKING = "SAVE_SUB_ID_TRACKING";
export const SAVE_SUB_ID_TRACKING_SUCCESS = "SAVE_SUB_ID_TRACKING_SUCCESS";
export const GET_SUB_ID_TRACKING = "GET_SUB_ID_TRACKING";
export const GET_SUB_ID_TRACKING_SUCCESS = "GET_SUB_ID_TRACKING_SUCCESS";
export const GET_STATE_ORGANIZATION_CONFIG = "GET_STATE_ORGANIZATION_CONFIG";
export const GET_STATE_ORGANIZATION_CONFIG_SUCCESS = "GET_STATE_ORGANIZATION_CONFIG_SUCCESS";
export const VALIDATE_TIERKEY = "VALIDATE_TIERKEY";
export const VALIDATE_TIERKEY_SUCCESS = "VALIDATE_TIERKEY_SUCCESS";

// ---------------------[ OTP Actions ]-----------------------
export const OTP_GET_STATUS = "OTP_GET_STATUS";
export const OTP_GET_STATUS_SUCCESS = "OTP_GET_STATUS_SUCCESS";
export const OTP_SEND_CODE = "OTP_SEND_CODE";
export const OTP_SEND_CODE_SUCCESS = "OTP_SEND_CODE_SUCCESS";
export const OTP_VERIFY_CODE = "OTP_VERIFY_CODE";
export const OTP_VERIFY_CODE_SUCCESS = "OTP_VERIFY_CODE_SUCCESS";

// ---------------------[ Process OTP Actions ]-----------------------
export const OTP_PROCESS_DATA = "OTP_PROCESS_DATA";
export const OTP_PROCESS_DATA_SUCCESS = "OTP_PROCESS_DATA_SUCCESS";

// ---------------------[ Process Payroll Actions ]-----------------------
export const PAYROLL_PROCESS_DATA = "PAYROLL_PROCESS_DATA";
export const PAYROLL_PROCESS_DATA_SUCCESS = "PAYROLL_PROCESS_DATA_SUCCESS";

// ---------------------[ Optout actions ]----------------------
export const SAVE_OPTOUT_PREFERENCES = "SAVE_OPTOUT_PREFERENCES";
export const SAVE_OPTOUT_PREFERENCES_SUCCESS = "SAVE_OPTOUT_PREFERENCES_SUCCESS";

// ---------------------[ dashboard actions ]----------------------

export const GET_STATEMENT_PDF = "GET_STATEMENT_PDF";
export const GET_LOAN_AGREEMENT_ERROR_POP_UP_MESSAGE = "GET_LOAN_AGREEMENT_ERROR_POP_UP_MESSAGE";
export const RESET_DASHBOARD_REDUCER = "RESET_DASHBOARD_REDUCER";
export const GET_PERSONAL_INFO = "GET_PERSONAL_INFO";
export const GET_PERSONAL_INFO_SUCCESS = "GET_PERSONAL_INFO_SUCCESS";
export const GET_STATEMENTS = "GET_STATEMENTS";
export const GET_STATEMENTS_SUCCESS = "GET_STATEMENTS_SUCCESS";
export const GET_STATEMENT_DETAILS = "GET_STATEMENT_DETAILS";
export const GET_STATEMENT_DETAILS_SUCCESS = "GET_STATEMENT_DETAILS_SUCCESS";
export const CLEAR_SELECTED_STATEMENT = "CLEAR_SELECTED_STATEMENT";
export const CLEAR_STATEMENTS = "CLEAR_STATEMENTS";
export const GET_SIGNED_AGREEMENTS = "GET_SIGNED_AGREEMENTS";
export const GET_SIGNED_AGREEMENTS_SUCCESS = "GET_SIGNED_AGREEMENTS_SUCCESS";
export const GET_AGREEMENT_DETAILS = "GET_AGREEMENT_DETAILS";
export const GET_AGREEMENT_DETAILS_SUCCESS = "GET_AGREEMENT_DETAILS_SUCCESS";
export const RESET_AGREEMENT_DETAILS = "RESET_AGREEMENT_DETAILS";
export const GET_HISTORY = "GET_HISTORY";
export const GET_HISTORY_SUCCESS = "GET_HISTORY_SUCCESS";
export const GET_HISTORY_DETAIL = "GET_HISTORY_DETAIL";
export const GET_HISTORY_DETAIL_SUCCESS = "GET_HISTORY_DETAIL_SUCCESS";

export const SET_COMMUNICATION_PREFS = "SET_COMMUNICATION_PREFS";
export const SAVE_COMMUNICATION_PREFS = "SAVE_COMMUNICATION_PREFS";
export const SAVE_COMMUNICATION_PREFS_SUCCESS = "SAVE_COMMUNICATION_PREFS_SUCCESS";
export const SAVE_COMMUNICATION_PREFS_ERROR = "SAVE_COMMUNICATION_PREFS_ERROR";

export const GET_ACTION_ITEMS = "GET_ACTION_ITEMS";
export const GET_ACTION_ITEMS_SUCCESS = "GET_ACTION_ITEMS_SUCCESS";
export const SAVE_TAGS_ON_ACTION_ITEMS_SUCCESS = "SAVE_TAGS_ON_ACTION_ITEMS_SUCCESS";

export const GET_BANK_VERIFICATION_ITEMS = "GET_BANK_VERIFICATION_ITEMS";
export const GET_BANK_VERIFICATION_ITEMS_SUCCESS = "GET_BANK_VERIFICATION_ITEMS_SUCCESS";
export const GET_BANK_VERIFICATION_CONFIRMED = "GET_BANK_VERIFICATION_CONFIRMED";
export const GET_BANK_VERIFICATION_CONFIRMED_SUCCESS = "GET_BANK_VERIFICATION_CONFIRMED_SUCCESS";
export const RESET_BV_CHECK = "RESET_BV_CHECK";
export const CHECK_BV_DATA_READY = "CHECK_BV_DATA_READY";
export const CHECK_BV_DATA_READY_SUCCESS = "CHECK_BV_DATA_READY_SUCCESS";
export const CHECK_BV_DATA_READY_FAILURE = "CHECK_BV_DATA_READY_FAILURE";
export const GET_POLL_PARAMS = "GET_POLL_PARAMS";
export const GET_POLL_PARAMS_SUCCESS = "GET_POLL_PARAMS_SUCCESS";
export const GET_POLL_PARAMS_FAILURE = "GET_POLL_PARAMS_FAILURE";
export const CHECK_BV_CONN_STATUS = "CHECK_BV_CONN_STATUS";

export const GET_ACH_DETAILS = "GET_ACH_DETAILS";
export const GET_ACH_DETAILS_SUCCESS = "GET_ACH_DETAILS_SUCCESS";
export const VERIFY_EFFECTIVE_DATE = "VERIFY_EFFECTIVE_DATE";
export const REEXPRESS = "REEXPRESS";
export const UPDATE_CUSTOMER_INFO_AND_REEXPRESS = "UPDATE_CUSTOMER_INFO_AND_REEXPRESS";

export const INITIATE_DRAW = "INITIATE_DRAW";
export const INITIATE_DRAW_SUCCESS = "INITIATE_DRAW_SUCCESS";
export const INITIATE_DRAW_ERROR = "INITIATE_DRAW_ERROR";

export const UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT";
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";
export const DOCUMENT_UPLOAD_ENABLED = "DOCUMENT_UPLOAD_ENABLED";
export const DOCUMENT_UPLOAD_ENABLED_SUCCESS = "DOCUMENT_UPLOAD_ENABLED_SUCCESS";

export const IS_CUSTOMER_TX_EPIC_IMPORT = "IS_CUSTOMER_TX_EPIC_IMPORT";
export const IS_CUSTOMER_TX_EPIC_IMPORT_SUCCESS = "IS_CUSTOMER_TX_EPIC_IMPORT_SUCCESS";
export const UPDATE_CONTACT_INFO = "UPDATE_CONTACT_INFO";
export const UPDATE_COMM_PREFERENCE = "UPDATE_COMM_PREFERENCE";
export const PAYMENT_CARD_ENABLED = "PAYMENT_CARD_ENABLED";
export const PAYMENT_CARD_ENABLED_SUCCESS = "PAYMENT_CARD_ENABLED_SUCCESS";
export const GET_STATEMENT_BALANCE = "GET_STATEMENT_BALANCE";
export const GET_STATEMENT_BALANCE_SUCCESS = "GET_STATEMENT_BALANCE_SUCCESS";
export const GET_CARD_PAYMENT_PREVIEW = "GET_CARD_PAYMENT_PREVIEW";
export const GET_CARD_PAYMENT_PREVIEW_SUCCESS = "GET_CARD_PAYMENT_PREVIEW_SUCCESS";
export const SUBMIT_CARD_PAYMENT = "SUBMIT_CARD_PAYMENT";
export const GET_PAYMENT_URL = "GET_PAYMENT_URL";
export const GET_PAYMENT_URL_SUCCESS = "GET_PAYMENT_URL_SUCCESS"
export const SUBMIT_CARD_PAYMENT_SUCCESS = "SUBMIT_CARD_PAYMENT_SUCCESS";
export const RESET_PAYMENT_URL = "RESET_PAYMENT_URL"
export const TRACK_CARD_PAYMENT_PREVIEW = "TRACK_CARD_PAYMENT_PREVIEW";
export const SET_SHOW_LOADER = "SET_SHOW_LOADER";
export const UNSET_SHOW_LOADER = "UNSET_SHOW_LOADER";
export const SET_SHOW_ERROR = "SET_SHOW_ERROR";
export const UNSET_SHOW_ERROR = "UNSET_SHOW_ERROR";

// Flinks
export const SET_FLINKS_LOGIN_STARTED = "SET_FLINKS_LOGIN_STARTED";
export const SET_FLINKS_LOGIN_OAUTH = "SET_FLINKS_LOGIN_OAUTH";
export const SET_FLINKS_LOGIN_SUCCESS = "SET_FLINKS_LOGIN_SUCCESS";
export const SET_FLINKS_LOGIN_FAILURE = "SET_FLINKS_LOGIN_FAILURE";
export const SAVE_REQUEST_DETAILS = "SAVE_REQUEST_DETAILS";
export const SAVE_REQUEST_DETAILS_SUCCESS = "SAVE_REQUEST_DETAILS_SUCCESS";
export const SAVE_REQUEST_DETAILS_FAILURE = "SAVE_REQUEST_DETAILS_FAILURE";

// TDC
export const GET_TDC_PERSONAL_INFO = "GET_TDC_PERSONAL_INFO";
export const GET_TDC_PERSONAL_INFO_SUCCESS = "GET_TDC_PERSONAL_INFO_SUCCESS";
export const GET_TDC_REDIRECT_PAGE = "GET_TDC_REDIRECT_PAGE";
export const GET_TDC_REDIRECT_PAGE_SUCCESS = "GET_TDC_REDIRECT_PAGE_SUCCESS";

// NC Auto Fund
export const SUBMIT_AUTO_APPROVAL = "SUBMIT_AUTO_APPROVAL";
export const SUBMIT_AUTO_APPROVAL_SUCCESS = "SUBMIT_AUTO_APPROVAL_SUCCESS";

// --------------------[ progress bar actions ]--------------------
export const SET_LATEST_APPLICATION_STEP = "SET_LATEST_APPLICATION_STEP";
export const SET_PREVIOUS_PATH_NAME = "SET_PREVIOUS_PATH_NAME";

// ------------------------[ loan actions ]------------------------
export const RESET_LOAN_REDUCER = "RESET_LOAN_REDUCER";

export const GET_LOAN_LIMITS = "GET_LOAN_LIMITS";
export const GET_LOAN_LIMITS_SUCCESS = "GET_LOAN_LIMITS_SUCCESS";

export const GET_LOAN_LIMITS_BY_ORG_AND_STATE = "GET_LOAN_LIMITS_BY_ORG_AND_STATE";
export const GET_LOAN_LIMITS_BY_ORG_AND_STATE_SUCCESS = "GET_LOAN_LIMITS_BY_ORG_AND_STATE_SUCCESS";
export const GET_LOAN_LIMITS_BY_ORG_AND_STATE_FAILURE = "GET_LOAN_LIMITS_BY_ORG_AND_STATE_FAILURE";
export const RESET_STATE_IS_ACTIVE = "RESET_STATE_IS_ACTIVE";

export const GET_LOAN_LIMITS_FOR_RC_CUSTOMER = "GET_LOAN_LIMITS_FOR_RC_CUSTOMER";
export const GET_LOAN_LIMITS_FOR_RC_CUSTOMER_SUCCESS = "GET_LOAN_LIMITS_FOR_RC_CUSTOMER_SUCCESS";

export const GET_ACTIVE_LOAN = "GET_ACTIVE_LOAN";
export const GET_ACTIVE_LOAN_SUCCESS = "GET_ACTIVE_LOAN_SUCCESS";

export const GET_ACTIVE_LOAN_SUMMARY = "GET_ACTIVE_LOAN_SUMMARY";
export const GET_LATEST_NON_WITHDRAWN_LOAN_SUMMARY = "GET_LATEST_NON_WITHDRAWN_LOAN_SUMMARY";
export const GET_LATEST_NON_WITHDRAWN_LOAN_SUMMARY_SUCCESS = "GET_LATEST_NON_WITHDRAWN_LOAN_SUMMARY_SUCCESS";
export const GET_ACTIVE_LOAN_SUMMARY_SUCCESS = "GET_ACTIVE_LOAN_SUMMARY_SUCCESS";
export const UPDATE_ACTIVE_LOAN_DETAILS = "UPDATE_ACTIVE_LOAN_DETAILS";
export const UPDATE_ACTIVE_LOAN_ENTITY_ID = "UPDATE_ACTIVE_LOAN_ENTITY_ID";

export const GET_LOAN_DOCS_BY_CUSTOMER = "GET_LOAN_DOCS_BY_CUSTOMER";
export const GET_LOAN_DOCS_BY_CUSTOMER_SUCCESS = "GET_LOAN_DOCS_BY_CUSTOMER_SUCCESS";

export const GET_LOAN_DOC_CONTENT = "GET_LOAN_DOC_CONTENT";
export const GET_LOAN_DOC_CONTENT_SUCCESS = "GET_LOAN_DOC_CONTENT_SUCCESS";

export const SAVE_LOAN_AGREEMENT = "SAVE_LOAN_AGREEMENT";
export const SAVE_LOAN_AGREEMENT_SUCCESS = "SAVE_LOAN_AGREEMENT_SUCCESS";

export const GET_KYC_QUESTIONS = "GET_KYC_QUESTIONS";
export const GET_KYC_QUESTIONS_SUCCESS = "GET_KYC_QUESTIONS_SUCCESS";

export const SAVE_KYC_QA = "SAVE_KYC_QA";
export const SAVE_KYC_QA_SUCCESS = "SAVE_KYC_QA_SUCCESS";

export const RESET_KYC_REMAINING_SECONDS = "RESET_KYC_REMAINING_SECONDS";
export const SAVE_KYC_TIMEOUT = "SAVE_KYC_TIMEOUT";

export const GET_KYC_REAPPLY_TIME = "GET_KYC_REAPPLY_TIME";
export const GET_KYC_REAPPLY_TIME_SUCCESS = "GET_KYC_REAPPLY_TIME_SUCCESS";

export const IS_CREDITFRESH_SUPPORTED = "IS_CREDITFRESH_SUPPORTED";
export const IS_CREDITFRESH_SUPPORTED_SUCCESS = "IS_CREDITFRESH_SUPPORTED_SUCCESS";
export const SET_CREDITFRESH_LIMIT = "SET_CREDITFRESH_LIMIT";

export const UPDATE_ACTIVE_LOAN_AMOUNT = "UPDATE_ACTIVE_LOAN_AMOUNT";
export const UPDATE_LOAN_LIMIT_HISTORY = "UPDATE_LOAN_LIMIT_HISTORY";

export const GET_PAYMENT_SCHEDULE = "GET_PAYMENT_SCHEDULE";
export const GET_PAYMENT_INFO = "GET_PAYMENT_INFO";
export const GET_PAYMENT_TRANSACTION_INFO = "GET_PAYMENT_TRANSACTION_INFO";
export const GET_PAYMENT_TRANSACTION_INFO_SUCCESS = "GET_PAYMENT_TRANSACTION_INFO_SUCCESS";
export const GET_PAYMENT_SCHEDULE_SUCCESS = "GET_PAYMENT_SCHEDULE_SUCCESS";
export const GET_PAYMENT_INFO_SUCCESS = "GET_PAYMENT_INFO_SUCCESS";

export const SUBMIT_RELOAN = "SUBMIT_RELOAN";
export const SUBMIT_RELOAN_SUCCESS = "SUBMIT_RELOAN_SUCCESS";

export const CHECK_DRAW_ELIGIBILITY = "CHECK_DRAW_ELIGIBILITY";
export const CHECK_DRAW_ELIGIBILITY_SUCCESS = "CHECK_DRAW_ELIGIBILITY_SUCCESS";

export const SHOW_LOC_LOWER_FEE = "SHOW_LOC_LOWER_FEE";
export const SHOW_LOC_LOWER_FEE_SUCCESS = "SHOW_LOC_LOWER_FEE_SUCCESS";
export const SAVE_LOC_LOWER_FEE_ACKNOWLEDGEMENT = "SAVE_LOC_LOWER_FEE_ACKNOWLEDGEMENT";
export const SAVE_LOC_LOWER_FEE_ACKNOWLEDGEMENT_SUCCESS = "SAVE_LOC_LOWER_FEE_ACKNOWLEDGEMENT_SUCCESS";
export const GET_LOC_LOWER_FEE_LEVEL = "GET_LOC_LOWER_FEE_LEVEL";
export const GET_LOC_LOWER_FEE_LEVEL_SUCCESS = "GET_LOC_LOWER_FEE_LEVEL_SUCCESS";

export const SAVE_DOCUMENT_VIEWED_ON_DASHBOARD = "SAVE_DOCUMENT_VIEWED_ON_DASHBOARD";
export const SAVE_DOCUMENT_VIEWED_ON_DASHBOARD_SUCCESS = "SAVE_DOCUMENT_VIEWED_ON_DASHBOARD_SUCCESS";
export const RETRIEVE_ANNUAL_INTEREST_RATE = "RETRIEVE_ANNUAL_INTEREST_RATE";
export const RETRIEVE_ANNUAL_INTEREST_RATE_SUCCESS = "RETRIEVE_ANNUAL_INTEREST_RATE_SUCCESS";

// -----------------------[ Consent content actions ]------------------------
export const GET_CONSENT_DOCUMENT = "GET_CONSENT_DOCUMENT";
export const GET_CONSENT_DOCUMENT_SUCCESS = "GET_CONSENT_DOCUMENT_SUCCESS";
export const GET_CONTENT_BY_DOC_NAME = "GET_CONTENT_BY_DOC_NAME";
export const GET_CONTENT_BY_DOC_NAME_SUCCESS = "GET_CONTENT_BY_DOC_NAME_SUCCESS";

export const SAVE_CONSENT_DOCUMENT = "SAVE_CONSENT_DOCUMENT";
export const SAVE_CONSENT_DOCUMENT_SUCCESS = "SAVE_CONSENT_DOCUMENT_SUCCESS";

export const CLEAR_CONTENT = "CLEAR_CONTENT";

export const GET_CUSTOMER_BANKING_INFORMATION = "GET_CUSTOMER_BANKING_INFORMATION";
export const GET_CUSTOMER_BANKING_INFORMATION_SUCCESS = "GET_CUSTOMER_BANKING_INFORMATION_SUCCESS";

// -----------------------[ other actions ]------------------------

// redux form actions to use in tests
export const REDUX_FORM_STOP_ASYNC_VALIDATION = "@@redux-form/STOP_ASYNC_VALIDATION";
export const REDUX_FORM_START_ASYNC_VALIDATION = "@@redux-form/START_ASYNC_VALIDATION";

export const REDUX_FORM_DESTROY_APPLICATION_FORMS = "REDUX_FORM_DESTROY_APPLICATION_FORMS";

// redux router actions to use in tests
export const REDUX_ROUTE_CALL_HISTORY_METHOD = "@@router/CALL_HISTORY_METHOD";

// tracking action
export const TRACKING_BEACON = "TRACKING_BEACON";

// tracking toast msg action
export const CREATE_AND_TRACK_TOAST_MESSAGE = "CREATE_AND_TRACK_TOAST_MESSAGE";

// -----------------------[ Organization actions ]------------------------
export const POPULATE_ORGANIZATION_INITIAL_CONFIG = "POPULATE_ORGANIZATION_INITIAL_CONFIG";
export const SET_ORGANIZATION_CONFIG = "SET_ORGANIZATION_CONFIG";
    export const SET_ORGANIZATION_CONTENT = "SET_ORGANIZATION_CONTENT";
export const RESET_ORGANIZATION_CONTENT = "RESET_ORGANIZATION_CONTENT";
export const GET_ORGANIZATION_CONFIG = "GET_ORGANIZATION_CONFIG";
export const GET_ORGANIZATION_CONFIG_SUCCESS = "GET_ORGANIZATION_CONFIG_SUCCESS";

// -----------------------[ General actions ]------------------------
export const IS_CHAT_ACTIVE = "IS_CHAT_ACTIVE";
export const IS_CHAT_ACTIVE_SUCCESS = "IS_CHAT_ACTIVE_SUCCESS";
export const GET_CHAT_HOURS = "GET_CHAT_HOURS";
export const GET_CHAT_HOURS_SUCCESS = "GET_CHAT_HOURS_SUCCESS";
export const SUBMIT_FEEDBACK_FORM = "SUBMIT_FEEDBACK_FORM";
export const SUBMIT_FEEDBACK_FORM_SUCCESS = "SUBMIT_FEEDBACK_FORM_SUCCESS";
export const SUBMIT_DEBT_VALIDATION = "SUBMIT_DEBT_VALIDATION";
export const GET_SERVER_TIME = "GET_SERVER_TIME";
export const GET_SERVER_TIME_SUCCESS = "GET_SERVER_TIME_SUCCESS";
export const EMAIL_OPT_OUT = "EMAIL_OPT_OUT";
// -----------------------[ create reject page action ]------------------------
export const GET_REJECT_PAGE_CAMPAIGN_URL = "GET_REJECT_PAGE_CAMPAIGN_URL";

// -----------------------[ router saga actions ]------------------------
export const EXECUTE_MISC_AUTH_ACTIONS = "EXECUTE_MISC_AUTH_ACTIONS";
export const EXECUTE_MISC_DASHBOARD_ACTIONS = "EXECUTE_MISC_DASHBOARD_ACTIONS";
export const EXECUTE_MISC_APPLICATION_ACTIONS = "EXECUTE_MISC_APPLICATION_ACTIONS";

// -----------------------[reapply loan actions]-------------------------

export const GET_REAPPLY_APPLICATION_DATA = "GET_REAPPLY_APPLICATION_DATA";
export const GET_REAPPLY_APPLICATION_DATA_SUCCESS = "GET_REAPPLY_APPLICATION_DATA_SUCCESS";
export const SAVE_PERSONAL_INFO_DATA = "SAVE_PERSONAL_INFO_DATA";
export const SAVE_EMPLOYMENT_INFO_DATA = "SAVE_EMPLOYMENT_INFO_DATA";
export const SUBMIT_RAL_APPLICATION_DATA = "SUBMIT_RAL_APPLICATION_DATA";
export const SUBMIT_RAL_APPLICATION_DATA_SUCCESS = "SUBMIT_RAL_APPLICATION_DATA_SUCCESS";
export const VALIDATE_DM_CODE = "VALIDATE_DM_CODE";
export const VALIDATE_DM_CODE_SUCCESS = "VALIDATE_DM_CODE_SUCCESS";
export const VALIDATE_DM_APPLICATION_CODE = "VALIDATE_DM_APPLICATION_CODE";
export const VALIDATE_DM_APPLICATION_CODE_SUCCESS = "VALIDATE_DM_APPLICATION_CODE_SUCCESS";
export const CLEAR_OFFER_PAGE_VALIDATED = "CLEAR_OFFER_PAGE_VALIDATED";
export const CLEAR_DM_PROMOCODE_DETAILS = "CLEAR_DM_PROMOCODE_DETAILS";
export const CLEAR_TIERKEY_MESSAGE = "CLEAR_TIERKEY_MESSAGE";
export const SAVE_IS_PROMOCODE_VALID = "SAVE_IS_PROMOCODE_VALID";
export const GET_BANKING_INFORMATION_FOR_RC = "GET_BANKING_INFORMATION_FOR_RC";
export const GET_BANKING_INFORMATION_FOR_RC_SUCCESS = "GET_BANKING_INFORMATION_FOR_RC_SUCCESS";
export const GET_VERIFICATION_STATUS = "GET_VERIFICATION_STATUS";
export const GET_VERIFICATION_STATUS_SUCCESS = "GET_VERIFICATION_STATUS_SUCCESS";
export const SEND_VERIFICATION_CODE = "SEND_VERIFICATION_CODE";
export const SEND_VERIFICATION_CODE_THROUGH_CALL = "SEND_VERIFICATION_CODE_THROUGH_CALL";
export const SEND_VERIFICATION_CODE_SUCCESS = "SEND_VERIFICATION_CODE_SUCCESS";
export const SUBMIT_VERIFICATION_CODE = "SUBMIT_VERIFICATION_CODE";
export const SUBMIT_VERIFICATION_CODE_SUCCESS = "SUBMIT_VERIFICATION_CODE_SUCCESS";
export const SUBMIT_EMAIL_VERIFICATION_OTP_CODE = "SUBMIT_EMAIL_VERIFICATION_OTP_CODE";
export const SUBMIT_EMAIL_VERIFICATION_OTP_CODE_SUCCESS = "SUBMIT_EMAIL_VERIFICATION_OTP_CODE_SUCCESS";
export const SKIP_PHONE_VERIFICATION = "SKIP_PHONE_VERIFICATION";
export const ADD_PHONE_VERIFICATION_SYSTEM_NOTE = "ADD_PHONE_VERIFICATION_SYSTEM_NOTE";


//-----------------------[Trust Ev Session actions] ------------------------

export const ADD_TRUST_EV_SESSION = "ADD_TRUST_EV_SESSION";

//-----------------------[Credit Limit Increase Actions] --------------------

export const ACCEPT_CREDIT_LIMIT_INCREASE = "ACCEPT_CREDIT_LIMIT_INCREASE";
export const ACCEPT_CREDIT_LIMIT_INCREASE_SUCCESS = "ACCEPT_CREDIT_LIMIT_INCREASE_SUCCESS";
export const SET_OFFER_LINK_FLAG = "SET_OFFER_LINK_FLAG";

//-----------------------[Online Payment] --------------------------------
export const SAVE_ONLINE_PAYMENT_DETAILS = "SAVE_ONLINE_PAYMENT_DETAILS";
export const SUBMIT_ONLINE_PAYMENT = "SUBMIT_ONLINE_PAYMENT";
export const CHECK_IS_DEBIT_CARD = "CHECK_IS_DEBIT_CARD";

//-----------------------[Spouse Info] --------------------------------
export const SAVE_SPOUSAL_INFO = "SAVE_SPOUSAL_INFO";
export const SAVE_SPOUSAL_INFO_SUCCESS = "SAVE_SPOUSAL_INFO_SUCCESS";

//-----------------------[Account Hold] --------------------------------
export const IS_ACCOUNT_ON_HOLD = "IS_ACCOUNT_ON_HOLD";
export const IS_ACCOUNT_ON_HOLD_SUCCESS = "IS_ACCOUNT_ON_HOLD_SUCCESS";

//-----------------------[Onfido Actions] --------------------------------
export let CREATE_ONFIDO_SDK = "CREATE_ONFIDO_SDK";
export let CREATE_ONFIDO_SDK_SUCCESS = "CREATE_ONFIDO_SDK_SUCCESS";
export let ONFIDO_SDK_EVENT = "ONFIDO_SDK_EVENT";
export let ONFIDO_SDK_EVENT_SUCCESS = "ONFIDO_SDK_EVENT_SUCCESS";
export let ONFIDO_SDK_EVENT_TRACKING = "ONFIDO_SDK_EVENT_TRACKING";
export let ONFIDO_SDK_EVENT_TRACKING_SUCCESS = "ONFIDO_SDK_EVENT_TRACKING_SUCCESS";

//-------------------------[MFA Actions] -------------------------------
export const IS_MFA_ENABLED = "IS_MFA_ENABLED";
export const IS_MFA_ENABLED_FOR_ORG = "IS_MFA_ENABLED_FOR_ORG";
export const DISABLE_MFA = "DISABLE_MFA";
export const CHECK_MFA_STATUS = "CHECK_MFA_STATUS";
export const CHECK_MFA_STATUS_FOR_ORG = "CHECK_MFA_STATUS_FOR_ORG";
export const VERIFY_PASSWORD = "VERIFY_PASSWORD";
export const UPDATE_SESSION_ID = "UPDATE_SESSION_ID";
export const ADD_EMAIL_TO_AUTH = "ADD_EMAIL_TO_AUTH";
export const CREATE_AND_TRACK_MFA_TOAST_MESSAGE = "CREATE_AND_TRACK_MFA_TOAST_MESSAGE";
export const SUBMIT_PASSWORD_VERIFICATION_SUCCESS = "SUBMIT_PASSWORD_VERIFICATION_SUCCESS";
export const SEND_MFA_VERIFICATION_CODE_SUCCESS = "SEND_MFA_VERIFICATION_CODE_SUCCESS";
export const MFA_SMS_SERVICE_DOWN = "MFA_SMS_SERVICE_DOWN";
export const CHECK_IS_RC_FOR_MFA_LOGIN = "CHECK_IS_RC_FOR_MFA_LOGIN";
export const MFA_SUBMIT_CODE_RESPONSE = "MFA_SUBMIT_CODE_RESPONSE";
export const SEND_MFA_VERIFICATION_CODE_FAIL = "SEND_MFA_VERIFICATION_CODE_FAIL";
export const RESET_MFA_VERIFICATION_ERROR = "RESET_MFA_VERIFICATION_ERROR";
export const RESET_MFA_PASSWORD_VERIFIED = "RESET_MFA_PASSWORD_VERIFIED";
export const SUBMIT_MFA_VERIFICATION_SUCCESS = "SUBMIT_MFA_VERIFICATION_SUCCESS";
export const MFA_DISABLED_SUCCESS = "MFA_DISABLED_SUCCESS";
export const SHOW_MFA_NOTIFICATION_SUCCESS = "SHOW_MFA_NOTIFICATION_SUCCESS";
export const RESET_MFA_PASSWORD_VERIFICATION_STATUS = "RESET_MFA_PASSWORD_VERIFICATION_STATUS"
export const SEND_MFA_VERIFICATION_CODE = "SEND_MFA_VERIFICATION_CODE";
export const SUBMIT_MFA_VERIFICATION_CODE = "SUBMIT_MFA_VERIFICATION_CODE";
export const CHECK_IF_MFA_NOTIFICATION_TO_BE_SHOWN = "CHECK_IF_MFA_NOTIFICATION_TO_BE_SHOWN";
export const SKIP_MFA_NOTIFICATION_SCREEN = "SKIP_MFA_NOTIFICATION_SCREEN";

//-------------------------[OTP Login Actions] -------------------------------
export const SEND_OTP_LOGIN_EMAIL = "SEND_OTP_LOGIN_EMAIL";
export const SEND_OTP_LOGIN_EMAIL_SUCCESS = "SEND_OTP_LOGIN_EMAIL_SUCCESS";
export const SUBMIT_OTP_LOGIN_VERIFICATION_CODE = "SUBMIT_OTP_LOGIN_VERIFICATION_CODE";
export const SUBMIT_OTP_LOGIN_VERIFICATION_CODE_SUCCESS = "SUBMIT_OTP_LOGIN_VERIFICATION_CODE_SUCCESS";
export const SUBMIT_OTP_LOGIN_VERIFICATION_CODE_INCORRECT = "SUBMIT_OTP_LOGIN_VERIFICATION_CODE_INCORRECT";
export const POST_OTP_LOGIN_UPDATE = "POST_OTP_LOGIN_UPDATE";
export const RESET_OTP_LOGIN_COUNTS = "RESET_OTP_LOGIN_COUNTS";

//-------------------------------[One Signal]------------------------------------
export const SAVE_ONE_SIGNAL_USER = "ONE_SIGNAL_USER";
export const SAVE_ONE_SIGNAL_USER_SUCCESS = "ONE_SIGNAL_USER_SUCCESS";

export const SEND_NOTIFICATIONS = "SEND_NOTIFICATIONS";

export const SEND_NOTIFICATIONS_SUCCESS = "SEND_NOTIFICATIONS_SUCCESS";

//----------------------------[CEP]----------------------------------------------
export const CREDIT_EVOLUTION_STATUS = "GET_CREDIT_EVOLUTION_STATUS";
export const CREDIT_EVOLUTION_DETAIL = "GET_CREDIT_EVOLUTION_DETAIL";
export const CREDIT_EVOLUTION_STATUS_SUCCESS = "CREDIT_EVOLUTION_STATUS_SUCCESS";
export const PRICING_MODEL_DETAILS = "PRICING_MODEL_DETAILS";
export const PRICING_MODEL_DETAILS_SUCCESS = "PRICING_MODEL_DETAILS_SUCCESS";
export const CREDIT_EVOLUTION_DETAIL_SUCCESS = "CREDIT_EVOLUTION_DETAIL_SUCCESS";

export const CANCEL_DRAW = "CANCEL_DRAW";
export const CANCEL_DRAW_SUCCESS = "CANCEL_DRAW_SUCCESS";

// -----------------------[ Insurance actions ]------------------------
export const SUBMIT_INSURANCE_ENROLLMENT = "SUBMIT_INSURANCE_ENROLLMENT";
export const SUBMIT_INSURANCE_ENROLLMENT_SUCCESS = "SUBMIT_INSURANCE_ENROLLMENT_SUCCESS";
export const SUBMIT_PAYMENT_PROTECTION_PLAN_DETAILS = "SUBMIT_PAYMENT_PROTECTION_PLAN_DETAILS";
export const UPDATE_PAYMENT_PROTECTION_PLAN_DETAILS = "UPDATE_PAYMENT_PROTECTION_PLAN_DETAILS";
export const RESET_INSURANCE_DATA = "RESET_INSURANCE_DATA";
export const GET_INSURANCE_STATUS = "GET_INSURANCE_STATUS";
export const GET_INSURANCE_STATUS_SUCCESS = "GET_INSURANCE_STATUS_SUCCESS";
export const GET_PAYMENT_PROTECTION_PLAN_DETAILS = "GET_PAYMENT_PROTECTION_PLAN_DETAILS";
export const GET_PAYMENT_PROTECTION_PLAN_DETAILS_SUCCESS = "GET_PAYMENT_PROTECTION_PLAN_DETAILS_SUCCESS";
export const GET_ACTION_ITEM_COMPLETED_SUCCESS = "GET_ACTION_ITEM_COMPLETED_SUCCESS";
export const SHOW_INSURANCE_POPUP = "SHOW_INSURANCE_POPUP";
export const SHOW_INSURANCE_POPUP_SUCCESS = "SHOW_INSURANCE_POPUP_SUCCESS";
export const LOG_INSURANCE_POPUP_TIME = "LOG_INSURANCE_POPUP_TIME";
export const RESET_INSURANCE_POPUP = "RESET_INSURANCE_POPUP";

// -----------------------[ Standalone ACH ]------------------------
export const GET_ACH_INFO = "GET_ACH_INFO";
export const GET_ACH_INFO_SUCCESS = "GET_ACH_INFO_SUCCESS";
export const GET_FILLED_DOCUMENT = "GET_FILLED_DOCUMENT";
export const GET_FILLED_DOCUMENT_SUCCESS = "GET_FILLED_DOCUMENT_SUCCESS";
export const SUBMIT_SIGNED_DOCUMENT = "SUBMIT_SIGNED_DOCUMENT";
export const SUBMIT_SIGNED_DOCUMENT_SUCCESS = "SUBMIT_SIGNED_DOCUMENT_SUCCESS";

// LPP Onetime payment

export const GET_LPP_PAYMENT_URL = "GET_LPP_PAYMENT_URL";
export const GET_LPP_PAYMENT_URL_SUCCESS = "GET_LPP_PAYMENT_URL_SUCCESS";
