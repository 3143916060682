/**
 * This Action file is responsible for organic and external application actio
 */

import * as actionTypes from './actionTypes'
import * as field from '../../shared/constant/field'

/**
 * Reset application reducer action
 * @returns {{type: string, payload: {}}}
 */
export const resetApplicationReducer = () => {
    return {
        type: actionTypes.RESET_APPLICATION_REDUCER,
        payload: {}
    };
};

/**
 * Update application action
 * @param fieldName
 * @param fieldValue
 * @returns {{type: string, fieldName: *, fieldValue: *}}
 */
export const updateApplication = (fieldName, fieldValue) => {
    return {
        type: actionTypes.UPDATE_APPLICATION,
        fieldName: fieldName,
        fieldValue: fieldValue
    };
};

/**
 * Validate and Extract JWT Token from Hotbox
 * @param hbToken
 */
export const validateAndExtractJWTHBToken = hbToken => {
    return {
        type: actionTypes.VALIDATE_EXTRACT_JWTHB_TOKEN,
        JWTHBToken: hbToken
    };
};

/**
 * Save External Lead Query Parameters from the URL
 * @param queryParams
 */
export const saveExternalLeadQueryParams = data => {
    return {
        type: actionTypes.SAVE_EXTERNAL_LEAD_QUERY_PARAMS,
        payload: data
    };
};

/**
 * Action to trigger upon successful validation and extraction of HB JWT Token.
 * @param JWTHBPayload
 * @returns {{type: string, JWTHBPayload: *}}
 * @constructor
 */
export const validateAndExtractJWTHBTokenSuccess = JWTHBPayload => {
    return {
        type: actionTypes.VALIDATE_EXTRACT_JWTHB_TOKEN_SUCCESS,
        payload: JWTHBPayload
    };
};

export const getExternalCustomerAppInfo = customerId => {
    return {
        type: actionTypes.GET_EXTERNAL_CUSTOMER_APP_INFO,
        payload: {customerId}
    };
};

export const getExternalCustomerAppInfoSuccess = response => {
    return {
        type: actionTypes.GET_EXTERNAL_CUSTOMER_APP_INFO_SUCCESS,
        payload: {response}
    };
};

export const saveStartPage = values => {
    return {
        type: actionTypes.START_APPLICATION,
        payload: {...values}
    };
};

export const saveStateSelection = values => {
    return {
        type: actionTypes.SAVE_STATE_SELECTION,
        payload: {...values}
    };
};

export const saveStartPageSuccess = response => {
    return {
        type: actionTypes.START_APPLICATION_SUCCESS,
        payload: {response}
    };
};

export const saveStartPageError = error => {
    return {
        type: actionTypes.START_APPLICATION_ERROR,
        payload: {error}
    };
};

export const isUniqueEmailAddress = (form, field, emailAddress) => {
    return {
        type: actionTypes.IS_UNIQUE_EMAIL_ADDRESS,
        payload: {
            form,
            field,
            emailAddress
        }
    };
};

export const checkEmailStatusAndSubmitStateInfo = (form, field, emailAddress, data) => {
    return {
        type: actionTypes.CHECK_EMAIL_STATUS_AND_SUBMIT_STATE_INFO,
        payload: {
            form,
            field,
            emailAddress,
            data
        }
    };
};

export const isUniqueSSNGlobally = (userAppInfoId, email, ssn, organizationId) => {
    return {
        type: actionTypes.IS_UNIQUE_SSN_GLOBALLY,
        payload: {
            userAppInfoId,
            email,
            ssn,
            organizationId
        }
    };
};

export const savePersonalInformationPage = values => {
    return {
        type: actionTypes.START_PERSONALINFO,
        payload: {
            [field.USERAPPINFO]: {...values}
        }
    };
};

export const saveAddressInformationPage = values => {
    return {
        type: actionTypes.START_ADDRESSINFO,
        payload: {
            [field.USERAPPINFO]: {...values}
        }
    };
};

export const saveLivingSituationPage = values => {
    return {
        type: actionTypes.START_LIVINGSITUATION,
        payload: {
            [field.USERAPPINFO]: {...values}
        }
    };
};

export const saveEmploymentInfo = (values, isOrganic) => {
    return {
        type: actionTypes.START_EMPLOYMENTINFO,
        payload: {
            [field.USERAPPINFO]: {...values},
            [field.IS_ORGANIC]: isOrganic
        }
    };
};

export const savePaymentSchedulePage = values => {
    return {
        type: actionTypes.START_PAYMENTSCHEDULE,
        payload: {
            [field.USERAPPINFO]: {...values}
        }
    };
};

export const saveBankingInfoPage = (values, isOrganic, isShortform) => {
    return {
        type: actionTypes.START_BANKINGINFO,
        payload: {
            [field.USERAPPINFO]: {...values},
            [field.IS_ORGANIC]: isOrganic,
            isShortform: isShortform
        }
    };
};

export const saveProductChangePopupAcknowledgement = (values) => {
    return {
        type: actionTypes.START_PRODUCT_CHANGE_POPUP_ACKNOWLEDGEMENT,
        payload: {
            [field.USERAPPINFO]: {...values}
        }
    };
};

export const saveProductChangePopupAcknowledgementSuccess = response => {
    return {
        type: actionTypes.START_PRODUCT_CHANGE_POPUP_ACKNOWLEDGEMENT_SUCCESS,
        payload: {response}
    };
};

export const saveCreatePasswordPage = values => {
    return {
        type: actionTypes.START_CREATEPASSWORD,
        payload: {...values}
    };
};

export const saveCreatePasswordPageSuccess = response => {
    return {
        type: actionTypes.START_CREATEPASSWORD_SUCCESS,
        payload: {response}
    };
};

export const saveEmailLoginResponse = (orgId, stateCode, email, value) => {
    return {
        type: actionTypes.EMAIL_LOGIN,
        payload: {
            orgId: orgId,
            stateCode: stateCode,
            email: email,
            value: value
        }
    }
}

export const saveEmailLoginResponseSuccess = (email) => {
    return {
        type: actionTypes.EMAIL_LOGIN_SUCCESS,
        payload: {
            email
        }
    }
}

export const saveCreatePasswordPageError = error => {
    return {
        type: actionTypes.START_CREATEPASSWORD_ERROR,
        payload: error
    };
};

export const saveApplicationSuccess = response => {
    return {
        type: actionTypes.SAVE_APPLICATION_SUCCESS,
        payload: {response}
    };
};

export const savePhoneNumberUpdate = (phoneNumber) => {
    return {
        type: actionTypes.UPDATE_PHONE_NUMBER,
        payload: phoneNumber
    }
}

export const saveHBPreStageCallSuccess = response => {
    return {
        type: actionTypes.START_HB_PRE_STAGE_CALL_SUCCESS,
        payload: {response}
    };
};

export const saveHBPostStageCallSuccess = response => {
    return {
        type: actionTypes.START_HB_POST_STAGE_CALL_SUCCESS,
        payload: {response}
    };
};

// -----[External Application Actions] ------
export const saveCreateAccountPage = ({userId, email, password, econsent, documents, organizationId, currentPage}) => {
    return {
        type: actionTypes.START_CREATEACCOUNT,
        payload: {userId, email, password, econsent, documents, organizationId, currentPage}
    };
};

export const saveCreateAccountSuccess = payload => {
    return {
        type: actionTypes.SAVE_CREATE_ACCOUNT_SUCCESS,
        payload: payload
    };
};

export const saveVerifyPhonePage = values => {
    return {
        type: actionTypes.START_VERIFYPHONE,
        payload: {...values}
    };
};

export const saveVerifyPhoneSuccess = response => {
    return {
        type: actionTypes.VERIFY_PHONE_SUCCESS,
        payload: {response}
    };
};

// -----[KYC Actions] ------
export const getKYCQuestions = (leadSequenceId, loanHeaderId) => {
    return {
        type: actionTypes.GET_KYC_QUESTIONS,
        payload: {
            leadSequenceId,
            loanHeaderId
        }
    };
};

export const getKYCQuestionsSuccess = response => {
    return {
        type: actionTypes.GET_KYC_QUESTIONS_SUCCESS,
        payload: {
            response
        }
    };
};

export const saveKYCQA = (leadSequenceId, customerId, loanHeaderId, qa) => {
    return {
        type: actionTypes.SAVE_KYC_QA,
        payload: {
            leadSequenceId,
            customerId,
            loanHeaderId,
            qa
        }
    };
};

export const saveKYCQASuccess = response => {
    return {
        type: actionTypes.SAVE_KYC_QA_SUCCESS,
        payload: {response}
    };
};

export const resetKYCRemainingSeconds = () => {
    return {
        type: actionTypes.RESET_KYC_REMAINING_SECONDS,
        payload: {}
    };
};

export const saveKYCTimeout = (loanHeaderId, leadSequenceId) => {
    return {
        type: actionTypes.SAVE_KYC_TIMEOUT,
        payload: {
            loanHeaderId,
            leadSequenceId
        }
    };
};

export const getKYCReapplyTime = loanHeaderId => {
    return {
        type: actionTypes.GET_KYC_REAPPLY_TIME,
        payload: {
            loanHeaderId
        }
    };
};

export const getKYCReapplyTimeSuccess = response => {
    return {
        type: actionTypes.GET_KYC_REAPPLY_TIME_SUCCESS,
        payload: {response}
    };
};

export const isCreditfreshSupported = stateKey => {
    return {
        type: actionTypes.IS_CREDITFRESH_SUPPORTED,
        payload: {
            stateKey
        }
    };
};

export const isCreditfreshSupportedSuccess = response => {
    return {
        type: actionTypes.IS_CREDITFRESH_SUPPORTED_SUCCESS,
        payload: {response}
    };
};
export const setCreditFreshLimit = response => {
    return {
        type: actionTypes.SET_CREDITFRESH_LIMIT,
        payload: {response}
    };
};

export const saveUrlParams = data => {
    return {
        type: actionTypes.SAVE_URL_PARAMS,
        payload: data
    };
};

export const saveOptOutPreferences = ({
    firstName,
    lastName,
    ssn,
    dob,
    affBusinessPurposeOptOut,
    affMarketingOptOut,
    nonAffMarketingOptOut,
    organizationId
}) => {
    return {
        type: actionTypes.SAVE_OPTOUT_PREFERENCES,
        payload: {
            firstName,
            lastName,
            ssn,
            dob,
            affBusinessPurposeOptOut,
            affMarketingOptOut,
            nonAffMarketingOptOut,
            organizationId
        }
    };
};

export const saveOptOutPreferencesSuccess = response => {
    return {
        type: actionTypes.SAVE_OPTOUT_PREFERENCES_SUCCESS,
        payload: {
            response
        }
    };
};

export const getRejectPageCampaignUrl = redirectUnsupported => {
    return {
        type: actionTypes.GET_REJECT_PAGE_CAMPAIGN_URL,
        payload: {
            redirectUnsupported
        }
    };
};

export const saveAddressInfo = ({address, address2, city, state, zip}) => {
    return {
        type: actionTypes.UPDATE_USERAPPINFO,
        payload: {
            address,
            address2,
            city,
            state,
            zip
        }
    };
};

export const getReapplyApplicationDataSuccess = result => {
    return {
        type: actionTypes.GET_REAPPLY_APPLICATION_DATA_SUCCESS,
        payload: {result}
    };
};

export const submitRALApplicationDataSuccess = payload => {
    return {
        type: actionTypes.SUBMIT_RAL_APPLICATION_DATA_SUCCESS,
        payload
    };
};

export const savePersonalInfoData = (data, productName, flowName) => {
    return {
        type: actionTypes.SAVE_PERSONAL_INFO_DATA,
        payload: {
            data: data,
            productName: productName,
            flowName: flowName,
        },
    }
}

export const saveEmploymentInfoData = data => {
    return {
        type: actionTypes.SAVE_EMPLOYMENT_INFO_DATA,
        payload: data
    };
};

export const submitRALApplicationData = (data, productName, flowName) => {
    return {
        type: actionTypes.SUBMIT_RAL_APPLICATION_DATA,
        payload: {
            data: data,
            productName: productName,
            flowName: flowName,
        }
    };
};

export const validateDMCode = data => {
    return {
        type: actionTypes.VALIDATE_DM_CODE,
        payload: data
    };
};

export const validateDMCodeSuccess = data => {
    return {
        type: actionTypes.VALIDATE_DM_CODE_SUCCESS,
        payload: data
    };
};

export const updateUserLoginInfo = data => {
    let fedPayload = {};
    fedPayload[field.ASSOCIATED_ORG] = data.associatedOrg;
    fedPayload[field.UNIQUE] = data.unique;
    return {
        type: actionTypes.UPDATE_USER_LOGIN_INFO,
        payload: fedPayload
    };
};

export const clearDMPromoCodeDetails = data => {
    return {
        type: actionTypes.CLEAR_DM_PROMOCODE_DETAILS,
        payload: data
    }
}

export const clearTierKeyMessage = () => {
    return {
        type: actionTypes.CLEAR_TIERKEY_MESSAGE,
        payload: {}
    };
};

export const saveIsPromocodeValid = (data) => {
    return {
        type: actionTypes.SAVE_IS_PROMOCODE_VALID,
        payload: data
    };
}

export const validateDMApplicationCode = data => {
    return {
        type: actionTypes.VALIDATE_DM_APPLICATION_CODE,
        payload: data
    };
};

export const validateDMApplicationCodeSuccess = (data, email) => {
    return {
        type: actionTypes.VALIDATE_DM_APPLICATION_CODE_SUCCESS,
        payload: data,
        emailValue: email
    };
};

export const clearOfferPageValidated = () => {
    return {
        type: actionTypes.CLEAR_OFFER_PAGE_VALIDATED,
    };
};

export const updatePaymentScheduleChoices = choices => {
    return {
        type: actionTypes.UPDATE_PAYMENT_SCHEDULE_CHOICES,
        payload: choices
    };
};

// -----[Progress Actions] ------
export const updateLoanApplicationProgress = data => {
    return {
        type: actionTypes.UPDATE_USERLOANAPPPROGRESS,
        payload: data
    };
};

export const getStateSelectionOrganizationId = (data) => {
    return {
        type: actionTypes.GET_STATE_SELECTION_ORGANIZATION_ID,
        payload: data
    };
};

export const getStateSelectionOrganizationIdSuccess = data => {
    return {
        type: actionTypes.GET_STATE_SELECTION_ORGANIZATION_ID_SUCCESS,
        payload: data
    };
};

export const saveSubIdTracking = data => {
    return {
        type: actionTypes.SAVE_SUB_ID_TRACKING,
        payload: data
    };
};

export const saveSubIdTrackingSuccess = response => {
    return {
        type: actionTypes.SAVE_SUB_ID_TRACKING_SUCCESS,
        payload: {
            response
        }
    };
};

export const getSubIdTracking = data => {
    return {
        type: actionTypes.GET_SUB_ID_TRACKING,
        payload: data
    };
};

export const getSubIdTrackingSuccess = response => {
    return {
        type: actionTypes.GET_SUB_ID_TRACKING_SUCCESS,
        payload: {
            response
        }
    };
};

export const getBankingInformationForRC = customerId => {
    return {
        type: actionTypes.GET_BANKING_INFORMATION_FOR_RC,
        payload: {
            customerId
        }
    };
};

export const getBankingInformationForRCSuccess = response => {
    return {
        type: actionTypes.GET_BANKING_INFORMATION_FOR_RC_SUCCESS,
        payload: {
            response
        }
    };
};

export const getVerificationStatus = (userId, contactNumber, isMobile) => {
    return {
        type: actionTypes.GET_VERIFICATION_STATUS,
        payload: {
            userId,
            contactNumber,
            isMobile

        }
    };
};

export const getVerificationStatusSuccess = response => {
    return {
        type: actionTypes.GET_VERIFICATION_STATUS_SUCCESS,
        payload: {
            response
        }
    };
};

export const sendVerificationCode = (userId, isMobile) => {
    return {
        type: actionTypes.SEND_VERIFICATION_CODE,
        payload: {
            userId,
            isMobile
        }
    };
};

export const sendVerificationCodeThroughCall = (userId, isMobile) => {
    return {
        type: actionTypes.SEND_VERIFICATION_CODE_THROUGH_CALL,
        payload: {
            userId,
            isMobile
        }
    };
};

export const sendVerificationCodeSuccess = response => {
    return {
        type: actionTypes.SEND_VERIFICATION_CODE_SUCCESS,
        payload: {
            response
        }
    };
};

export const submitVerificationCode = (userId, baseLoanId, verificationCode, hasMobile, form, field) => {
    return {
        type: actionTypes.SUBMIT_VERIFICATION_CODE,
        payload: {
            userId, baseLoanId, verificationCode, hasMobile, form, field
        }
    };
};

export const submitVerificationCodeSuccess = response => {
    return {
        type: actionTypes.SUBMIT_VERIFICATION_CODE_SUCCESS,
        payload: {
            response
        }
    };
};

export const skipPhoneVerification = userId => {
    return {
        type: actionTypes.SKIP_PHONE_VERIFICATION,
        payload: {
            userId
        }
    };
};

export const verifyPassword = (userId, password, form, field) => {
    return {
        type: actionTypes.VERIFY_PASSWORD,
        payload: {
            userId, password, form, field
        }
    };
};

export const submitPasswordVerificationSuccess = response => {
    return {
        type: actionTypes.SUBMIT_PASSWORD_VERIFICATION_SUCCESS,
        payload: {
            response
        }
    };
};

export const resetMfaPasswordVerificationState = () => {
    return {
        type: actionTypes.RESET_MFA_PASSWORD_VERIFICATION_STATUS,
        payload: {}
    };
};

export const sendMfaVerificationCode = (userId, mfaSetupRequest, email, password, isResend, contactType) => {
    return {
        type: actionTypes.SEND_MFA_VERIFICATION_CODE,
        payload: {
            userId, mfaSetupRequest, email, password, isResend, contactType
        }
    };
};

export const sendMfaVerificationCodeSuccess = response => {
    return {
        type: actionTypes.SEND_MFA_VERIFICATION_CODE_SUCCESS,
        payload: {
            response
        }
    };
};

export const sendMfaSMSServiceDown = isSMSServiceDown => {
    return {
        type: actionTypes.MFA_SMS_SERVICE_DOWN,
        payload: {
            isSMSServiceDown
        }
    };
};

export const checkIfRCForMfaLogin = (isRC) => {
    return {
        type: actionTypes.CHECK_IS_RC_FOR_MFA_LOGIN,
        payload: {
            isRC
        }
    }
}
export const mfaVerificationCodeResponse = () => {
    return {
        type: actionTypes.MFA_SUBMIT_CODE_RESPONSE,
        payload: {
        }
    }
}
export const sendMfaVerificationCodeErrorMessage = response =>{
    return {
        type: actionTypes.SEND_MFA_VERIFICATION_CODE_FAIL,
        payload: {
            response
        }
    };
}

export const submitMfaVerificationSuccess = response => {
    return {
        type: actionTypes.SUBMIT_MFA_VERIFICATION_SUCCESS,
        payload: {
            response
        }
    };
}

export const submitMfaVerificationCode = (userId, verificationCode, form, field, mfaSetupRequest, email, password) => {
    return {
        type: actionTypes.SUBMIT_MFA_VERIFICATION_CODE,
        payload: {
            userId, verificationCode, form, field, mfaSetupRequest, email, password
        }
    };
};

export const checkIfMfaEnabled = (userId) => {
    return {
        type: actionTypes.CHECK_MFA_STATUS,
        payload: {
            userId
        }
    };
};

export const checkIfMfaEnabledForOrg = (userId) => {
    return {
        type: actionTypes.CHECK_MFA_STATUS_FOR_ORG,
        payload: {
            userId
        }
    };
};

export const isMfaEnabled = response => {
    return {
        type: actionTypes.IS_MFA_ENABLED,
        payload: {
            response
        }
    };
}

export const isMfaEnabledForOrg = response => {
    return {
        type: actionTypes.IS_MFA_ENABLED_FOR_ORG,
        payload: {
            response
        }
    };
}

export const disableMfa = (userId) => {
    return {
        type: actionTypes.DISABLE_MFA,
        payload: {
            userId
        }
    };
}

export const isMfaDisabled = response => {
    return {
        type: actionTypes.MFA_DISABLED_SUCCESS,
        payload: {
            response
        }
    };
}

export const skipMfaNotificationScreen = (userId) => {
    return {
        type: actionTypes.SKIP_MFA_NOTIFICATION_SCREEN,
        payload: {
            userId
        }
    };
}

export const checkIfMfaNotificationCanBeShown = (userId) => {
    return {
        type: actionTypes.CHECK_IF_MFA_NOTIFICATION_TO_BE_SHOWN,
        payload: {
            userId
        }
    };
}

export const showMfaNotificationSuccess = response => {
    return {
        type: actionTypes.SHOW_MFA_NOTIFICATION_SUCCESS,
        payload: {
            response
        }
    };
}

export const resetMfaPasswordVerified = () => {
    return {
        type: actionTypes.RESET_MFA_PASSWORD_VERIFIED,
        payload: {}
    };
}

export const resetMfaVerificationErrMessage = () => {
    return {
        type: actionTypes.RESET_MFA_VERIFICATION_ERROR,
        payload: {}
    };
}

export const sendOtpLoginEmail = (email) => {
    return {
        type: actionTypes.SEND_OTP_LOGIN_EMAIL,
        payload: {
            email
        }
    };
};

export const sendOtpLoginEmailSuccess = (response) => {
    return {
        type: actionTypes.SEND_OTP_LOGIN_EMAIL_SUCCESS,
        payload: {
            response
        }
    };
};

export const submitOtpLoginVerificationCode = (email, verificationCode, form, field) => {
    return {
        type: actionTypes.SUBMIT_OTP_LOGIN_VERIFICATION_CODE,
        payload: {
            email, verificationCode, form, field
        }
    };
};

export const submitOtpLoginVerificationCodeSuccess = (response) => {
    return {
        type: actionTypes.SUBMIT_OTP_LOGIN_VERIFICATION_CODE_SUCCESS,
        payload: {
            response
        }
    };
};

export const submitOtpLoginVerificationCodeIncorrect = () => {
    return {
        type: actionTypes.SUBMIT_OTP_LOGIN_VERIFICATION_CODE_INCORRECT,
        payload: {
        }
    };
};

export const resetOtpLoginCounts = () => {
    return {
        type: actionTypes.RESET_OTP_LOGIN_COUNTS,
        payload: {}
    };
}

export const saveOneSignalUser = (data) => {
    return {
        type: actionTypes.SAVE_ONE_SIGNAL_USER,
        payload: {data}
    };
};

export const saveOneSignalUserSuccess = response => {
    return {
        type: actionTypes.SAVE_ONE_SIGNAL_USER_SUCCESS,
        payload: {
            response
        }
    };
};

export const validateTierkey = (tierkey, externalReferenceId) => {
    return {
        type: actionTypes.VALIDATE_TIERKEY,
        payload: {
            tierkey, externalReferenceId
        }
    };
};

export const validateTierkeySuccess = (tierkey, externalReferenceId) => {
    return {
        type: actionTypes.VALIDATE_TIERKEY_SUCCESS,
        payload: {
            tierkey, externalReferenceId
        }
    };
};

