import {all, call, takeEvery} from "redux-saga/effects";
import * as actionTypes from "../action/actionTypes";
import * as applicationSagas from "./ApplicationSaga";
import * as authSagas from "./AuthSaga";
import * as contentSaga from "./ContentSaga";
import * as dashboardSagas from "./DashboardSaga";
import * as loanSaga from "./LoanSaga";
import * as organizationSaga from "./OrganizationSaga";
import * as trackingSaga from "./TrackingSaga";
import * as toastMessageSaga from "./ToastMessageSaga";
import * as reduxFormActionsSaga from "./reduxFormActionsSaga";
import * as routerSaga from "./RouterSaga";
import * as insuranceSaga from "./InsuranceSaga";

function* watchLogin() {
    yield all([
        takeEvery(actionTypes.LOGIN, authSagas.loginUser),
        takeEvery(actionTypes.LOGOUT, authSagas.logoutUser),
        takeEvery(actionTypes.DOES_EMAIL_EXIST, authSagas.doesEmailExist),
        takeEvery(actionTypes.EMAIL_RESET_PASSWORD_LINK, authSagas.emailResetPasswordLink),
        takeEvery(actionTypes.UPDATE_PASSWORD, authSagas.updatePassword),
        takeEvery(actionTypes.UPDATE_PASSWORD_WITHOUT_TOKEN, authSagas.updatePasswordWithoutToken),
        takeEvery(actionTypes.IS_MKWEB_RESET_PASSWORD_TOKEN_VALID, authSagas.isResetPasswordTokenValid),
        takeEvery(actionTypes.GET_LANDING_PAGE, authSagas.getLandingPage),
        takeEvery(actionTypes.SEND_EMAIL_VERIFICTION, authSagas.sendEmailVerification),
        takeEvery(actionTypes.SEND_EMAIL_VERIFICTION_ONE_TIME_PASSWORD, authSagas.sendEmailVerificationOneTimePassword),
        takeEvery(actionTypes.SUBMIT_EMAIL_VERIFICATION_OTP_CODE, authSagas.submitEmailVerificationOTPCode),
        takeEvery(actionTypes.VERIFY_EMAIL, authSagas.isEmailVerificationTokenValid),
        takeEvery(actionTypes.SEND_MFA_VERIFICATION_CODE, applicationSagas.sendMfaVerificationCode),
        takeEvery(actionTypes.SUBMIT_MFA_VERIFICATION_CODE, applicationSagas.submitMfaVerificationCode),
        takeEvery(actionTypes.CHECK_MFA_STATUS, applicationSagas.checkIfMfaEnabled),
        takeEvery(actionTypes.CHECK_MFA_STATUS_FOR_ORG, applicationSagas.checkIfMfaEnabledForOrg),
        takeEvery(actionTypes.DISABLE_MFA, applicationSagas.disableMfa),
        takeEvery(actionTypes.CHECK_IF_MFA_NOTIFICATION_TO_BE_SHOWN, applicationSagas.checkIfMfaNotificationCanBeShown),
        takeEvery(actionTypes.SKIP_MFA_NOTIFICATION_SCREEN, applicationSagas.skipMfaNotificationScreen),
        takeEvery(actionTypes.CREATE_AND_TRACK_MFA_TOAST_MESSAGE, authSagas.trackToastMessageForMfa),
        takeEvery(actionTypes.SEND_OTP_LOGIN_EMAIL, applicationSagas.sendOtpLoginEmail),
        takeEvery(actionTypes.SUBMIT_OTP_LOGIN_VERIFICATION_CODE, applicationSagas.submitOtpLoginVerificationCode),
        takeEvery(actionTypes.SAVE_ONE_SIGNAL_USER, applicationSagas.saveOneSignalUsers), takeEvery(actionTypes.SEND_NOTIFICATIONS, authSagas.sendNotifications)
    ]);
}

function* watchApplication() {
    yield all([
        takeEvery(actionTypes.VALIDATE_EXTRACT_JWTHB_TOKEN, applicationSagas.validateAndExtractHBJWTToken),
        takeEvery(actionTypes.SAVE_EXTERNAL_LEAD_QUERY_PARAMS, applicationSagas.saveExternalLeadQueryParams),
        takeEvery(actionTypes.SAVE_STATE_SELECTION, applicationSagas.saveStateSelection),
        takeEvery(actionTypes.START_APPLICATION, applicationSagas.saveStartPage),
        takeEvery(actionTypes.IS_UNIQUE_EMAIL_ADDRESS, applicationSagas.isUniqueEmailAddress),
        takeEvery(actionTypes.CHECK_EMAIL_STATUS_AND_SUBMIT_STATE_INFO, applicationSagas.checkEmailStatusAndSubmitStateInfo),
        takeEvery(actionTypes.IS_UNIQUE_SSN_GLOBALLY, applicationSagas.isUniqueSSNGlobally),
        takeEvery(actionTypes.START_PERSONALINFO, applicationSagas.savePersonalInformationPage),
        takeEvery(actionTypes.SAVE_PERSONAL_INFO_DATA, applicationSagas.savePersonalInfoData),
        takeEvery(actionTypes.START_ADDRESSINFO, applicationSagas.saveAddressInformationPage),
        takeEvery(actionTypes.START_LIVINGSITUATION, applicationSagas.saveLivingSituationPage),
        takeEvery(actionTypes.START_PAYMENTSCHEDULE, applicationSagas.savePaymentSchedulePage),
        takeEvery(actionTypes.START_EMPLOYMENTINFO, applicationSagas.saveEmploymentInformationPage),
        takeEvery(actionTypes.START_BANKINGINFO, applicationSagas.saveBankingInfoPage),
        takeEvery(actionTypes.START_CREATEPASSWORD, applicationSagas.saveCreatePasswordPage),
        takeEvery(actionTypes.UPDATE_USERLOANAPPPROGRESS, applicationSagas.updateLoanApplicationProgress),
        takeEvery(actionTypes.GET_VERIFICATION_STATUS, applicationSagas.getVerificationStatus),
        takeEvery(actionTypes.SEND_VERIFICATION_CODE, applicationSagas.sendVerificationCode),
        takeEvery(actionTypes.SEND_VERIFICATION_CODE_THROUGH_CALL, applicationSagas.sendVerificationCodeThroughCall),
        takeEvery(actionTypes.SUBMIT_VERIFICATION_CODE, applicationSagas.submitVerificationCode),
        takeEvery(actionTypes.SKIP_PHONE_VERIFICATION, applicationSagas.skipPhoneVerification),
        takeEvery(actionTypes.VALIDATE_TIERKEY, applicationSagas.validateTierkey),
        // External Flow
        takeEvery(actionTypes.GET_EXTERNAL_CUSTOMER_APP_INFO, applicationSagas.getExternalCustomerAppInfo),
        takeEvery(actionTypes.START_CREATEACCOUNT, applicationSagas.saveCreateAccountPage),
        takeEvery(actionTypes.START_VERIFYPHONE, applicationSagas.savePhoneVerificationPage),
        // KYC
        takeEvery(actionTypes.GET_KYC_QUESTIONS, applicationSagas.getKYCQuestions),
        takeEvery(actionTypes.SAVE_KYC_QA, applicationSagas.saveKYCQA),
        takeEvery(actionTypes.SAVE_KYC_TIMEOUT, applicationSagas.saveKYCTimeout),
        takeEvery(actionTypes.GET_KYC_REAPPLY_TIME, applicationSagas.getKYCReapplyTime),
        // Optout
        takeEvery(actionTypes.SAVE_OPTOUT_PREFERENCES, applicationSagas.saveOptOutPreferences),
        //Reject
        takeEvery(actionTypes.GET_REJECT_PAGE_CAMPAIGN_URL, applicationSagas.getRejectPageCampaignUrl),
        takeEvery(actionTypes.IS_CREDITFRESH_SUPPORTED, applicationSagas.isCreditfreshSupported),
        // Dashboard
        takeEvery(actionTypes.SAVE_COMMUNICATION_PREFS, dashboardSagas.saveCommunicationPrefs),
        takeEvery(actionTypes.GET_PERSONAL_INFO, dashboardSagas.getPersonalInformation),
        takeEvery(actionTypes.GET_STATEMENTS, dashboardSagas.getStatements),
        takeEvery(actionTypes.GET_SIGNED_AGREEMENTS, dashboardSagas.getSignedAgreements),
        takeEvery(actionTypes.GET_AGREEMENT_DETAILS, dashboardSagas.getAgreementDetails),
        takeEvery(actionTypes.GET_ACTION_ITEMS, dashboardSagas.getActionItems),
        takeEvery(actionTypes.GET_BANK_VERIFICATION_ITEMS, dashboardSagas.getBankVerificationItems),
        takeEvery(actionTypes.GET_BANK_VERIFICATION_CONFIRMED, dashboardSagas.getBankVerificationConfirmed),
        takeEvery(actionTypes.CHECK_BV_DATA_READY, dashboardSagas.checkBVDataReady),
        takeEvery(actionTypes.GET_POLL_PARAMS, dashboardSagas.getPollParameters),
        takeEvery(actionTypes.CHECK_BV_CONN_STATUS, dashboardSagas.checkBVConnectionStatus),
        takeEvery(actionTypes.SAVE_REQUEST_DETAILS, dashboardSagas.saveRequestDetails),
        takeEvery(actionTypes.GET_ACH_DETAILS, dashboardSagas.getAchDetails),
        takeEvery(actionTypes.VERIFY_EFFECTIVE_DATE, dashboardSagas.verifyEffectiveDate),
        takeEvery(actionTypes.REEXPRESS, dashboardSagas.reexpress),
        takeEvery(actionTypes.UPDATE_CUSTOMER_INFO_AND_REEXPRESS, dashboardSagas.updateCustomerInfoAndReExpress),
        takeEvery(actionTypes.INITIATE_DRAW, dashboardSagas.initiateDraw),
        takeEvery(actionTypes.GET_STATEMENT_DETAILS, dashboardSagas.getStatementDetails),
        takeEvery(actionTypes.GET_STATEMENT_PDF, dashboardSagas.getStatementPDF),
        takeEvery(actionTypes.GET_LOAN_AGREEMENT_ERROR_POP_UP_MESSAGE, dashboardSagas.getLoanAgreementErrorPopUpMessage),
        takeEvery(actionTypes.GET_HISTORY, dashboardSagas.getHistory),
        takeEvery(actionTypes.SUBMIT_RELOAN, dashboardSagas.submitReloan),
        takeEvery(actionTypes.GET_REAPPLY_APPLICATION_DATA, applicationSagas.getReapplyApplicationData),
        takeEvery(actionTypes.IS_CUSTOMER_TX_EPIC_IMPORT, dashboardSagas.isCustomerTXEpicImport),
        takeEvery(actionTypes.SUBMIT_RAL_APPLICATION_DATA, applicationSagas.submitRALApplicationData),
        takeEvery(actionTypes.VALIDATE_DM_CODE, applicationSagas.validateDMCode),
        takeEvery(actionTypes.VALIDATE_DM_APPLICATION_CODE, applicationSagas.validateDMApplicationCode),
        takeEvery(actionTypes.EMAIL_LOGIN, applicationSagas.saveEmailLoginResponse),
        takeEvery(actionTypes.GET_STATE_SELECTION_ORGANIZATION_ID, applicationSagas.getOrganizationId),
        takeEvery(actionTypes.UPDATE_CONTACT_INFO, dashboardSagas.updateContactInfo),
        takeEvery(actionTypes.CHECK_IS_DEBIT_CARD, dashboardSagas.isDebitCard),
        takeEvery(actionTypes.PAYMENT_CARD_ENABLED, dashboardSagas.isCardPaymentEnabled),
        takeEvery(actionTypes.GET_STATEMENT_BALANCE, dashboardSagas.getCurrentStatementBalance),
        takeEvery(actionTypes.GET_CARD_PAYMENT_PREVIEW, dashboardSagas.getCardPaymentPreview),
        takeEvery(actionTypes.SUBMIT_CARD_PAYMENT, dashboardSagas.submitPayment),
        takeEvery(actionTypes.GET_PAYMENT_URL, dashboardSagas.getPaymentUrl),
        takeEvery(actionTypes.GET_LPP_PAYMENT_URL, dashboardSagas.getLppPaymentUrl),
        takeEvery(actionTypes.TRACK_CARD_PAYMENT_PREVIEW, dashboardSagas.trackCardPaymentPreview),
        takeEvery(actionTypes.SAVE_SPOUSAL_INFO, dashboardSagas.saveSpousalInfo),
        takeEvery(
            actionTypes.START_PRODUCT_CHANGE_POPUP_ACKNOWLEDGEMENT,
            applicationSagas.saveProductChangePopupAcknowledgement
        ),
        takeEvery(actionTypes.CHECK_DRAW_ELIGIBILITY, dashboardSagas.checkDrawEligibility),
        takeEvery(actionTypes.SHOW_LOC_LOWER_FEE, dashboardSagas.showLocLowerFee),
        takeEvery(actionTypes.SAVE_LOC_LOWER_FEE_ACKNOWLEDGEMENT, dashboardSagas.saveLocLowerFeePopupAcknowledgement),
        takeEvery(actionTypes.SAVE_DOCUMENT_VIEWED_ON_DASHBOARD, dashboardSagas.saveDocumentViewedOnDashboard),
        takeEvery(actionTypes.GET_STATE_ORGANIZATION_CONFIG, organizationSaga.getStateOrganizationConfiguration),
        takeEvery(actionTypes.GET_BANKING_INFORMATION_FOR_RC, applicationSagas.getBankingInformationForRC),
        // OTP + Payroll
        takeEvery(actionTypes.OTP_GET_STATUS, dashboardSagas.otpGetStatus),
        takeEvery(actionTypes.OTP_SEND_CODE, dashboardSagas.otpSendCode),
        takeEvery(actionTypes.OTP_VERIFY_CODE, dashboardSagas.otpVerifyCode),
        takeEvery(actionTypes.OTP_PROCESS_DATA, dashboardSagas.otpProcessData),
        takeEvery(actionTypes.PAYROLL_PROCESS_DATA, dashboardSagas.payrollProcessData),
        // TDC
        takeEvery(actionTypes.GET_TDC_PERSONAL_INFO, dashboardSagas.getTDCPersonalInformation),
        takeEvery(actionTypes.GET_TDC_REDIRECT_PAGE, dashboardSagas.getTDCRedirectPage),
        //NC Auto Fund
        takeEvery(actionTypes.SUBMIT_AUTO_APPROVAL, dashboardSagas.submitForAutoApproval),
        // Document Upload
        takeEvery(actionTypes.UPLOAD_DOCUMENT, dashboardSagas.documentUpload),
        takeEvery(actionTypes.UPLOAD_DOCUMENT_SUCCESS, dashboardSagas.documentUploadSuccess),
        takeEvery(actionTypes.DOCUMENT_UPLOAD_ENABLED, dashboardSagas.isDocumentUploadEnabled),
        takeEvery(actionTypes.IS_ACCOUNT_ON_HOLD, dashboardSagas.isAccountOnHold),
        takeEvery(actionTypes.CREATE_ONFIDO_SDK, dashboardSagas.createOnfidoSdk),
        takeEvery(actionTypes.ONFIDO_SDK_EVENT, dashboardSagas.onfidoSdkEventCallback),
        takeEvery(actionTypes.ONFIDO_SDK_EVENT_TRACKING, dashboardSagas.onfidoSdkEventTracking),
        takeEvery(actionTypes.CREDIT_EVOLUTION_STATUS, dashboardSagas.getCreditEvolutionStatus),
        takeEvery(actionTypes.CREDIT_EVOLUTION_DETAIL, dashboardSagas.getCreditEvolutionDetail),
        takeEvery(actionTypes.PRICING_MODEL_DETAILS, dashboardSagas.getPricingModelDetails),
        takeEvery(actionTypes.VERIFY_PASSWORD, applicationSagas.verifyPassword),
        takeEvery(actionTypes.CANCEL_DRAW,dashboardSagas.cancelDraw),
        takeEvery(actionTypes.GET_ACH_INFO, dashboardSagas.getAchInfo),
        takeEvery(actionTypes.GET_FILLED_DOCUMENT, dashboardSagas.getFilledDocument),
        takeEvery(actionTypes.SUBMIT_SIGNED_DOCUMENT, dashboardSagas.submitSignedDocument)
    ]);
}

function* watchLoan() {
    yield all([
        takeEvery(actionTypes.GET_LOAN_LIMITS, loanSaga.getLoanLimits),
        takeEvery(actionTypes.GET_LOAN_LIMITS_BY_ORG_AND_STATE, loanSaga.getLoanLimitsByOrgAndState),
        takeEvery(actionTypes.GET_LOAN_LIMITS_FOR_RC_CUSTOMER, loanSaga.getLoanLimitsForRcCustomer),
        takeEvery(actionTypes.GET_ACTIVE_LOAN, loanSaga.getActiveLoan),
        takeEvery(actionTypes.GET_ACTIVE_LOAN_SUMMARY, loanSaga.getActiveLoanSummary),
        takeEvery(actionTypes.GET_LATEST_NON_WITHDRAWN_LOAN_SUMMARY, loanSaga.getLatestNonWithdrawnLoanSummary),
        takeEvery(actionTypes.GET_LOAN_DOCS_BY_CUSTOMER, loanSaga.getLoanDocsByCustomer),
        takeEvery(actionTypes.GET_LOAN_DOC_CONTENT, loanSaga.getLoanDocContentByDocVersionId),
        takeEvery(actionTypes.SAVE_LOAN_AGREEMENT, loanSaga.saveLoanAgreement),
        takeEvery(actionTypes.UPDATE_ACTIVE_LOAN_AMOUNT, loanSaga.updateActiveLoanAmount),
        takeEvery(actionTypes.UPDATE_LOAN_LIMIT_HISTORY, loanSaga.updateLoanLimitHistory),
        takeEvery(actionTypes.GET_PAYMENT_SCHEDULE, loanSaga.getPaymentSchedule),
        takeEvery(actionTypes.GET_PAYMENT_INFO, loanSaga.getPaymentInfo),
        takeEvery(actionTypes.GET_PAYMENT_TRANSACTION_INFO, loanSaga.getPaymentTransactionInfo),
        takeEvery(actionTypes.ACCEPT_CREDIT_LIMIT_INCREASE, loanSaga.acceptCreditLimitIncrease),
        takeEvery(actionTypes.GET_LOC_LOWER_FEE_LEVEL, loanSaga.getFeeLevel),
        takeEvery(actionTypes.GET_CUSTOMER_BANKING_INFORMATION, loanSaga.getBankingInformation),
        takeEvery(actionTypes.RETRIEVE_ANNUAL_INTEREST_RATE, loanSaga.retrieveAnnualInterestRate),
    ]);
}

function* watchContent() {
    yield all([
        takeEvery(actionTypes.GET_CONSENT_DOCUMENT, contentSaga.getConsentDocument),
        takeEvery(actionTypes.SAVE_CONSENT_DOCUMENT, contentSaga.saveConsentDocument),
        takeEvery(actionTypes.GET_CONTENT_BY_DOC_NAME, contentSaga.getContentByDocName)
    ]);
}

function* watchTracking() {
    yield all([
        takeEvery(actionTypes.CREATE_AND_TRACK_TOAST_MESSAGE, toastMessageSaga.trackToastMessage),
        takeEvery(actionTypes.TRACKING_BEACON, trackingSaga.tracking)
    ]);
}

function* watchInsurance() {
    yield all([
        takeEvery(actionTypes.GET_INSURANCE_STATUS, insuranceSaga.getInsuranceStatus),
        takeEvery(actionTypes.SUBMIT_INSURANCE_ENROLLMENT, insuranceSaga.submitInsuranceEnrollment),
        takeEvery(actionTypes.SUBMIT_PAYMENT_PROTECTION_PLAN_DETAILS, insuranceSaga.submitPaymentProtectionPlanDetails),
        takeEvery(actionTypes.GET_PAYMENT_PROTECTION_PLAN_DETAILS, insuranceSaga.getPaymentProtectionPlanDetails),
        takeEvery(actionTypes.SHOW_INSURANCE_POPUP, insuranceSaga.showInsurancePopup),
        takeEvery(actionTypes.LOG_INSURANCE_POPUP_TIME, insuranceSaga.logInsurancePopupTime)
    ]);
}

function* watchOrganization() {
    yield all([
        takeEvery(actionTypes.GET_ORGANIZATION_CONFIG, organizationSaga.getOrganizationConfig),
        takeEvery(actionTypes.IS_CHAT_ACTIVE, organizationSaga.isChatActive),
        takeEvery(actionTypes.GET_CHAT_HOURS, organizationSaga.getChatHours),
        takeEvery(actionTypes.SUBMIT_FEEDBACK_FORM, organizationSaga.submitFeedbackForm),
        takeEvery(actionTypes.SUBMIT_DEBT_VALIDATION, organizationSaga.submitDebtValidation),
        takeEvery(actionTypes.GET_SERVER_TIME, organizationSaga.getServerTime),
        takeEvery(actionTypes.EMAIL_OPT_OUT, organizationSaga.emailOptOut)
    ]);
}

function* watchReduxFormActionsSaga() {
    yield all([
        takeEvery(actionTypes.REDUX_FORM_DESTROY_APPLICATION_FORMS, reduxFormActionsSaga.destroyApplicationForms)
    ]);
}

function* watchRouterSaga() {
    yield all([
        takeEvery(actionTypes.EXECUTE_MISC_AUTH_ACTIONS, routerSaga.miscAuthActions),
        takeEvery(actionTypes.EXECUTE_MISC_DASHBOARD_ACTIONS, routerSaga.miscDashboardActions),
        takeEvery(actionTypes.EXECUTE_MISC_APPLICATION_ACTIONS, routerSaga.miscApplicationActions)
    ]);
}

function* rootSaga() {
    yield all([
        call(watchLogin),
        call(watchApplication),
        call(watchLoan),
        call(watchContent),
        call(watchTracking),
        call(watchOrganization),
        call(watchReduxFormActionsSaga),
        call(watchRouterSaga),
        call(watchInsurance)
    ]);
}

export default rootSaga;
