import React from "react";
import P from "prop-types";
import _ from "lodash";
import {createLocation} from "history";
import {Redirect, Route, withRouter} from "react-router-dom";
import * as utility from "./utility";
import {getUrlParameters} from "./utility";

import * as route from "../constant/route";
import * as constant from "../constant/constant";
import * as cookieUtility from "./cookieutility";
import * as field from "../constant/field";

export const APPLICATION_SUBPAGES = [
    route.APPLICATION_STATE_INFO,
    route.APPLICATION_APPLY_REQUIREMENTS_PAGE,
    route.APPLICATION_CREATE_PASSWORD,
    route.APPLICATION_ACCOUNT_TERMS,
    route.APPLICATION_PERSONAL_INFO,
    route.APPLICATION_ADDRESS_INFO,
    route.APPLICATION_LIVING_SITUATION,
    route.APPLICATION_PAYMENT_SCHEDULE,
    route.APPLICATION_PRE_APPROVED,
    route.APPLICATION_EMPLOYMENT_INFO,
    route.APPLICATION_BANKING_INFO,
    //route.APPLICATION_VERIFYYOURIDENTITY,
    route.APPLICATION_LOAN_DOCS,
    route.APPLICATION_FINAL_STEPS,
    route.EXTERNAL_APPLICATION_WELCOME,
    route.EXTERNAL_APPLICATION_CREATE_ACCOUNT,
    route.EXTERNAL_APPLICATION_PHONE_VERIFICATION,
    route.APPLICATION_VERIFY_PHONE_PAGE,
    route.APPLICATION_REJECT
];

/**
 * Pages flow for organic applications
 * @type {*[]}
 */
export const APPLICATION_ORGANIC_FLOW_PAGES = [
    route.APPLICATION_STATE_INFO_PAGE,
    route.APPLICATION_APPLY_REQUIREMENTS_PAGE,
    route.APPLICATION_CREATE_PASSWORD_PAGE,
    route.APPLICATION_ACCOUNT_TERMS_PAGE,
    route.APPLICATION_PERSONAL_INFO_PAGE,
    route.APPLICATION_EXTERNAL_PHONE_VERIFICATION_PAGE,
    route.APPLICATION_VERIFY_PHONE_PAGE,
    route.APPLICATION_ADDRESS_INFO_PAGE,
    // route.APPLICATION_LIVING_SITUATION_PAGE,
    route.APPLICATION_PAYMENT_SCHEDULE_PAGE,
    route.APPLICATION_PRE_APPROVED_PAGE,
    route.APPLICATION_EMPLOYMENT_INFO_PAGE,
    route.APPLICATION_BANKING_INFO_PAGE,
    // route.APPLICATION_KNOWYOURCUSTOMER_PAGE,
    route.APPLICATION_LOAN_DOCS_PAGE,
    // route.APPLICATION_FINAL_STEPS_PAGE,
    route.DASHBOARD_VIEW_ACCOUNT_PAGE,
    route.APPLICATION_REJECT
];

/**
 * Pages flow for external applications
 * @type {*[]}
 */
export const APPLICATION_EXTERNAL_FLOW_PAGES = [
    route.APPLICATION_EXTERNAL_WELCOME_PAGE,
    route.APPLICATION_APPLY_REQUIREMENTS_PAGE,
    route.APPLICATION_EXTERNAL_CREATE_ACCOUNT_PAGE,
    route.APPLICATION_ACCOUNT_TERMS_PAGE,
    route.APPLICATION_EXTERNAL_PHONE_VERIFICATION_PAGE,
    route.APPLICATION_VERIFY_PHONE_PAGE,
    //route.APPLICATION_ADDRESS_INFO_PAGE,
    route.APPLICATION_EMPLOYMENT_INFO_PAGE,
    route.APPLICATION_PAYMENT_SCHEDULE_PAGE,
    route.APPLICATION_BANKING_INFO_PAGE,
    route.APPLICATION_PRE_APPROVED_PAGE,

    //route.APPLICATION_KNOWYOURCUSTOMER_PAGE,

    route.APPLICATION_LOAN_DOCS_PAGE,
    // route.APPLICATION_FINAL_STEPS_PAGE,
    route.DASHBOARD_VIEW_ACCOUNT_PAGE,
    route.APPLICATION_REJECT
];

/**
 * Get the next page for external application based on current page
 * @param currentPage
 * @returns {*}
 */
function getNextExternalPage(currentPage, phoneVerificationFlow) {
    let currentPageIndex = _.indexOf(APPLICATION_EXTERNAL_FLOW_PAGES, currentPage);

    if(phoneVerificationFlow === false){
        if(currentPage === route.APPLICATION_EXTERNAL_PHONE_VERIFICATION_PAGE) {
            return route.APPLICATION_EMPLOYMENT_INFO_PAGE
        }
    }

    if(currentPageIndex===0) {
        return route.APPLICATION_APPLY_REQUIREMENTS_PAGE;
    } else {
        if (++currentPageIndex !== APPLICATION_EXTERNAL_FLOW_PAGES.length) {
            return APPLICATION_EXTERNAL_FLOW_PAGES[currentPageIndex];
        }
    }
}

/**
 * Get the next page for organic application based on current page
 * @param currentPage
 * @returns {*}
 */
function getNextOrganicPage(currentPage, phoneVerificationFlow) {
    let currentPageIndex = _.indexOf(APPLICATION_ORGANIC_FLOW_PAGES, currentPage);
    if(phoneVerificationFlow === false){
        if(currentPage === route.APPLICATION_PERSONAL_INFO_PAGE) {
            return route.APPLICATION_ADDRESS_INFO_PAGE;
        }
    }
    if(phoneVerificationFlow === true){
        if(currentPage === route.APPLICATION_VERIFY_PHONE_PAGE) {
            return route.APPLICATION_PAYMENT_SCHEDULE_PAGE;
        }
    }

    if(currentPageIndex===0) {
        if(utility.showApplicationRequirements()) {
            if (++currentPageIndex !== APPLICATION_ORGANIC_FLOW_PAGES.length) {
                return APPLICATION_ORGANIC_FLOW_PAGES[currentPageIndex];
            }
        }
        else{
            if (++currentPageIndex !== APPLICATION_ORGANIC_FLOW_PAGES.length) {
                return route.APPLICATION_CREATE_PASSWORD_PAGE;
            }
        }

    } else if (currentPageIndex === APPLICATION_ORGANIC_FLOW_PAGES.indexOf(route.APPLICATION_APPLY_REQUIREMENTS_PAGE)) {
        return route.APPLICATION_APPLY_REQUIREMENTS_PAGE;
    }

    if (currentPageIndex >= 0 && currentPageIndex !== 1) {
        if (++currentPageIndex !== APPLICATION_ORGANIC_FLOW_PAGES.length) {
            return APPLICATION_ORGANIC_FLOW_PAGES[currentPageIndex];
        }
    }
}

/**
 * Get the next page for application
 * @param currentPage Current page user is on
 * @param isOrganic Is application organic or external
 * @returns {*}
 */
export function getNextPage(currentPage, isOrganic, phoneVerificationFlow = false) {
    let nextPage;

    if (isOrganic) {
        nextPage = getNextOrganicPage(currentPage, phoneVerificationFlow);
    } else {
        nextPage = getNextExternalPage(currentPage, phoneVerificationFlow);
    }

    // If this is a last application page, then remove application state cookie.
    if (isLastSubpageofApplication(currentPage)) {
        cookieUtility.removeCookie(constant.COOKIE_MKWEBAPPLICATION_DATA);
    }

    return nextPage;
}

export function getOldApplicationPage(buildProfile, state, email) {
    if (buildProfile === "production") {
        window.location.href = `${constant.OLD_APPLICATION_PAGE}?email=${email}&state=${state}`;
    } else {
        //to generate dynamic url based on specific QA environment
        window.location.href = `https://${
            window.location.host.split("-")[0]
        }-www.moneykey.com/start-application?email=${email}&state=${state}`;
    }
}

/**
 * After banking info page, we show KYC if kyc flag is true,
 * else we show loan docs
 * @param isOrganic
 * @param isKYC
 */
export function getNextPageAfterBankingInfoPage(isOrganic, isKYC, isShortform, isLoanDocsinDashboard, isNCAutoFundFlow) {
    let nextPage;
    if (isKYC) {
        if (isOrganic) {
            nextPage = getNextOrganicPage(route.APPLICATION_BANKING_INFO_PAGE);
        } else {
            nextPage = getNextExternalPage(route.APPLICATION_BANKING_INFO_PAGE);
        }
    } else {
        if (isOrganic) {
            nextPage = getNextOrganicPage(route.APPLICATION_BANKING_INFO_PAGE);
        } else {
            if (isShortform === true || isNCAutoFundFlow === true) {
                nextPage = getNextExternalPage(route.APPLICATION_BANKING_INFO_PAGE);
            } else {
                nextPage = getNextExternalPage(route.APPLICATION_PRE_APPROVED_PAGE);
            }
        }
    }
    return getNextPageBasedBasedonLoanDocsPlacement(nextPage, isOrganic, isLoanDocsinDashboard);
}

/**
 * Verify phone page is a special page for external flow.
 */
export function getNextPageAfterVerifyPhonePage(isShortform, isKYC, isLoanDocsinDashboard, isNCAutoFundFlow, isOrganic, phoneVerificationFlow = false) {
    let nextPage;

    if(phoneVerificationFlow === true) {
        if (isOrganic) {
            nextPage = getNextOrganicPage(route.APPLICATION_EXTERNAL_PHONE_VERIFICATION_PAGE);
        } else {
            nextPage = getNextExternalPage(route.APPLICATION_EXTERNAL_PHONE_VERIFICATION_PAGE);
        }
    } else if (!isOrganic && (isShortform || isNCAutoFundFlow)) {
        nextPage = getNextExternalPage(route.APPLICATION_VERIFY_PHONE_PAGE, phoneVerificationFlow);
    } else if (isKYC === true) {
        nextPage = getNextExternalPage(route.APPLICATION_BANKING_INFO_PAGE);
    } else {
        nextPage = getNextExternalPage(route.APPLICATION_PRE_APPROVED_PAGE);
    }

    return getNextPageBasedBasedonLoanDocsPlacement(nextPage, false, isLoanDocsinDashboard);
}

export function getNextPageAfterPhoneVerificationCompletePage(isOrganic, isNCAutoFundFlow, isShortform) {

    if (isOrganic) {
        return getNextOrganicPage(route.APPLICATION_VERIFY_PHONE_PAGE, true)
    } else if (!isNCAutoFundFlow && !isShortform) {
        return getNextExternalPage(route.APPLICATION_PRE_APPROVED_PAGE, true);
    }

    return getNextExternalPage(route.APPLICATION_VERIFY_PHONE_PAGE, true);
}

export function getNextPageForShortForm(userAppInfo) {
    // Return Employment page
    if (
        !utility.isValidIncomeType(userAppInfo[field.INCOME_TYPE]) ||
        !utility.isValidPayType(userAppInfo[field.PAYMENT_TYPE])
    ) {
        return route.APPLICATION_EMPLOYMENT_INFO_PAGE;
    }

    if (
        userAppInfo[field.INCOME_TYPE] === "E" &&
        utility.isEmptyOrNullString(userAppInfo[field.EMPLOYER_NAME])
    ) {
        return route.APPLICATION_EMPLOYMENT_INFO_PAGE;
    }

    // Return Payment Schedule page
    /* if (
         !utility.isValidPayFrequency(userAppInfo[field.PAY_FREQUENCY]) ||
         utility.isEmptyOrNullString(userAppInfo[field.LAST_PAY_DATE]) ||
         utility.isEmptyOrNullString(userAppInfo[field.NEXT_PAY_DATE]) ||
         (utility.isEmptyOrNullString(userAppInfo[field.NMI]) &&
             utility.isEmptyOrNullString(userAppInfo[field.PAYCHECK_AMOUNT]))
     ) {
         return route.APPLICATION_PAYMENT_SCHEDULE;
     }*/
    // TKP-587: Mandating this page to ensure Payroll info is checked off from outstanding todos.
    return route.APPLICATION_PAYMENT_SCHEDULE;

    //return route.APPLICATION_BANKING_INFO;
}

/**
 * Get next page after loan amount update
 * @param currentPage
 * @param isOrganic
 * @param isRAL
 * @param isRC
 * @param productType
 * @param isKYC
 * @param isShortform
 * @param isLoanDocsinDashboard
 * @returns {*}
 */
export function getNextPageAfterLoanAmountUpdate(
    currentPage,
    isOrganic,
    isRAL,
    isXRAL,
    isRC,
    productType,
    isKYC,
    isShortform,
    isLoanDocsinDashboard
) {
    let nextPage;
    if (isOrganic) {
        if (isRAL && !isXRAL) {
            nextPage = getNextOrganicPage(route.APPLICATION_BANKING_INFO_PAGE);
        } else if (isRC && (utility.isLOCLoan(productType) || utility.isInstallmentLoan(productType))){
            nextPage = getNextOrganicPage(route.APPLICATION_BANKING_INFO_PAGE);
        } else {
            nextPage = getNextOrganicPage(currentPage);
        }
    } else {
        if (isShortform === true) {
            if (isKYC === true) {
                nextPage = getNextExternalPage(currentPage);
            } else {
                nextPage = getNextExternalPage(route.APPLICATION_PRE_APPROVED_PAGE);
            }
        } else {
            nextPage = getNextExternalPage(currentPage);
        }
    }
    return getNextPageBasedBasedonLoanDocsPlacement(nextPage, isOrganic, isLoanDocsinDashboard);
}

/**
 * Loan docs can be configured to be shown at application flow or at dashboard.
 * Based on it return the next page.
 * @param page
 * @param isOrganic
 * @param isLoanDocsinDashboard
 * @returns {*}
 */
function getNextPageBasedBasedonLoanDocsPlacement(page, isOrganic, isLoanDocsinDashboard) {
    let nextPage = page;
    if (page === route.APPLICATION_LOAN_DOCS_PAGE && isLoanDocsinDashboard === true) {
        if (isOrganic) {
            nextPage = getNextOrganicPage(page);
        } else {
            nextPage = getNextExternalPage(page);
        }
    }
    return nextPage;
}

/**
 * We do rehydrate state on specific pages from cookie for consistent user experience on page refreshes.
 * But there are certain pages, where we dont want to rehydrate state.
 * For ex: Welcome page on external application. We are going to reauthenticate user on this page, so dont rehydrate
 * auth data from cookie on this page.
 *
 * @param currentPage
 * @returns {boolean}
 */
export function isPageEligibleForRehydratingAuthState(currentPage) {
    let welcomePagefromHBRedirectRegEx = /welcome\/[\S]+\.[\S]+\.[\S]+/gim;
    return currentPage !== route.APPLICATION_HOME_PAGE && welcomePagefromHBRedirectRegEx.test(currentPage) === false;


}

/**
 * We do rehydrate application state on all application pages except first page (state info page).
 * @param currentPage
 * @returns {boolean}
 */
export function isPageEligibleForRehydratingApplicationState(currentPage) {
    let welcomePagefromHBRedirectRegEx = /welcome\/[\S]+\.[\S]+\.[\S]+/gim;
    let applicationSubpage = getApplicationSubPage(currentPage);
    if (isAnyOfApplicationPage(currentPage)) {
        if (
            applicationSubpage !== route.APPLICATION_STATE_INFO &&
            welcomePagefromHBRedirectRegEx.test(currentPage) === false &&
            !_.isEmpty(applicationSubpage)
        ) {
            return true;
        }
    }

    return false;
}

export function isPageEligibleForRehydratingLoanState(currentPage) {
    let routes = [
        route.APPLICATION_EXTERNAL_CREATE_ACCOUNT_PAGE,
        route.APPLICATION_ACCOUNT_TERMS_PAGE,
        route.APPLICATION_EXTERNAL_PHONE_VERIFICATION_PAGE,
        route.APPLICATION_EMPLOYMENT_INFO_PAGE,
        route.APPLICATION_PAYMENT_SCHEDULE_PAGE,
        route.APPLICATION_BANKING_INFO_PAGE,
        route.APPLICATION_PRE_APPROVED_PAGE,
        route.APPLICATION_REJECT_PAGE,
        route.ONLINE_PAYMENT_PAGE,
        route.CONFIRM_PAYMENT_PAGE,
        route.ONLINE_PAYMENT_CONFIRMATION_PAGE,
        route.DASHBOARD_STATEMENTS,
        route.REAPPLY_PERSONAL_INFO_PAGE,
        route.REAPPLY_EMPLOYMENT_INFO_PAGE,
        route.DASHBOARD_CREDIT_LIMIT_INCREASE,
        route.DASHBOARD_CREDIT_EVOLUTION_DETAILS_PAGE,
        route.PROFILE_VIEW_ACH_AUTH_PAGE
    ];

    return routes.find(route => currentPage.indexOf(route) >= 0);
}

export function isPageEligibleForRehydratingDashboardState(currentPage) {
    return currentPage.indexOf(route.DASHBOARD_BASE_ROUTE_PARAMETER) >= 0;
}

export function isPageEligibleForRehydratingInsuranceState(currentPage) {
    let routes = [
        route.DASHBOARD_PAYMENT_PROTECTION_PLAN_PAGE,
        route.DASHBOARD_PAYMENT_PROTECTION_ACKNOWLEDGEMENT_PAGE,
        route.DASHBOARD_PRE_AUTH_DEBIT_SUMMARY_PAGE,
        route.DASHBOARD_PRE_AUTH_DEBIT_SET_UP_PAGE,
        route.DASHBOARD_PRE_AUTH_DEBIT_CONFIRMATION_PAGE
    ];

    return routes.find(route => currentPage.indexOf(route) >= 0);
}


/**
 * Returns true if its a first page of organic or external application
 * @param currentPage
 * @returns {boolean}
 */
export function isApplicationStartPage(currentPage) {
    let applicationSubpage = getApplicationSubPage(currentPage);

    return applicationSubpage === route.APPLICATION_STATE_INFO ||
        applicationSubpage === route.EXTERNAL_APPLICATION_WELCOME ||
        _.isEmpty(applicationSubpage);
}

/**
 * Returns true if its a state info page
 * @param currentPage
 * @returns {boolean}
 */
export function isApplicationStateInfoPage(currentPage) {
    let applicationSubpage = getApplicationSubPage(currentPage);

    return applicationSubpage === route.APPLICATION_STATE_INFO || _.isEmpty(applicationSubpage);
}

/**
 * This function returns that is given page is part of any of application page?
 * If so, return true, else return false
 * @param currentPage
 * @returns {boolean}
 */
export function isAnyOfApplicationPage(currentPage) {
    return currentPage.indexOf(route.APPLICATION_BASE_ROUTE_PARAMETER) >= 0 &&
        currentPage.indexOf(route.REAPPLY_BASE_ROUTE_PARAMETER) < 0;
}

/**
 * This function returns that is given page is part of any of dashboard page?
 * If so return true, else return false
 * @param currentPage
 * @returns {boolean}
 */
export function isAnyOfDashboardPage(currentPage) {
    return currentPage.indexOf(route.DASHBOARD_BASE_ROUTE_PARAMETER) >= 0;
}

/**
 * Get subpage from the currentPage.
 * For ex: /apply/stateinfo, then stateinfo will be the subpage
 * for /apply, we DO NOT want to return stateinfo as the subpage
 * @param currentPage
 */
export function getApplicationSubPage(currentPage) {
    let subPage = null;
    _.forEach(APPLICATION_SUBPAGES, function(value) {
        if (currentPage.indexOf(value) >= 0) {
            subPage = value;
        }
    });

    return subPage;
}

/**
 * If current page is the last subpage of an application,
 * then return true.
 * We want to perform special things such as removing application cookie after
 * last application page is submitted to avoid the reuse.
 *
 * @param currentPage
 */
export function isLastSubpageofApplication(currentPage) {
    return currentPage.indexOf(route.APPLICATION_LOAN_DOCS) >= 0;
}

/**
 * If current page is the loan docs in an application
 * then return true.
 *
 * @param currentPage
 */
export function isLoanDocsPage(currentPage) {
    return currentPage.indexOf(route.APPLICATION_LOAN_DOCS) >= 0;
}

export function isWelcomePage(currentPage) {
    return currentPage.indexOf(route.APPLICATION_EXTERNAL_WELCOME_PAGE) > -1;
}

/**
 * If current page is the account terms page
 * then return true.
 *
 * @param currentPage
 */
export function isAccountTermPage(currentPage) {
    return currentPage.indexOf(route.APPLICATION_ACCOUNT_TERMS) >= 0;
}

/**
 * If current page is the loan docs in an application
 * then return true.
 *
 * @param currentPage
 */
export function isLoginPage(currentPage) {
    return currentPage.indexOf(route.LOGIN) >= 0;
}

/**
 * If current page is shown before the landing page. (Ex. Email OTP Login)
 * then return true.
 *
 * @param currentPage
 */
export function isBeforeLandingLogic(currentPage) {
    return currentPage.indexOf(route.APPLICATION_OTP_EMAIL_VERIFICATION) >= 0;
}

export function isDashboardPageWithoutBreadcrumbs(currentPage) {
    let routes = [
        route.DASHBOARD_OTP_EMAIL_VERIFICATION,
        route.DASHBOARD_UPDATE_PASSWORD,
    ];

    return routes.find(route => currentPage.indexOf(route) >= 0);
}

/**
 *Get reject page
 * @returns {string}
 */
export function getRejectPage(rejectReason) {
    return route.APPLICATION_REJECT_PAGE + "?reject=" + rejectReason;
}

/**
 * Get login page
 * @returns {string}
 */
export function getStateLoginPage() {
    return route.STATE_LOGIN + window.location.search;
}

export function getRedirectPagewithEmailQueryString(email, pageName){
    let queryFromURL = window.location.search;
    if(queryFromURL){
        if(queryFromURL.indexOf("email=") >= 0){
            return `${pageName}${window.location.search}`;
        } else {
            return `${pageName}${window.location.search}&email=${email}`;
        }
    } else {
        return `${pageName}?email=${email}`;
    }
}

export function getApplicationLoginPage() {
    return route.APPLICATION_LOGIN_PAGE;
}

export function getEmailLoginPage() {
    return route.EMAIL_LOGIN_PAGE;
}

export function getLoginPage() {
    let organizationId = utility.getOrganizationId();

    switch (organizationId) {
        case constant.FORA_ORGANIZATION_ID:
        case constant.FORA_PLUS_ORGANIZATION_ID:
        case constant.PATHWARD_ORGANIZATION_ID:
            return route.EMAIL_LOGIN_PAGE;
        default:
            return route.STATE_LOGIN + window.location.search;
    }
}

export function getApplyNowStartPage() {
    let organizationId = utility.getOrganizationId();
    switch (organizationId){
        case constant.FORA_ORGANIZATION_ID:
        case constant.FORA_PLUS_ORGANIZATION_ID:
            return route.APPLICATION_START_APP_PAGE + window.location.search;
        case constant.PATHWARD_ORGANIZATION_ID:
            return route.APPLICATION_STATE_INFO_PAGE + window.location.search;
        default:
            return route.APPLICATION_STATE_SELECTION_PAGE + window.location.search;
    }
}

export function getOfferPage() {
    return route.OFFERS_PAGE;
}

export function isLongFormAndNCAutoFundEnabled(isNCAutoFundFlow, isOrganic, isShortform) {
    return isNCAutoFundFlow && !isOrganic && !isShortform
}

export function isLongFormAndNCAutoFundDisabled(isNCAutoFundFlow, isOrganic, isShortform) {
    return !isNCAutoFundFlow && !isOrganic && !isShortform
}

export function isNCAutoFundFlow(isNCAutoFundFlow, isRC, isRAL, isXRAL) {
    return isNCAutoFundFlow && !isRC && (!isRAL || isXRAL);
}

/**
 * APPLICATION PAGE SWITCH
 -------------------------------------------------------------------------- */

export const KEY_ApplicationPageSwitch__lockedpath = "ApplicationPageSwitch.lockedpath";
export const KEY_ApplicationPageSwitch__lockedpathmessage = "ApplicationPageSwitch.lockedpathmessage";
export const FORWARD_LOCKING_PATHS = [
    route.APPLICATION_ACCOUNT_TERMS_PAGE,
    route.APPLICATION_LOAN_DOCS_PAGE,
    route.APPLICATION_PRE_APPROVED_PAGE,
    route.APPLICATION_REJECT
];

export function lockApplicationPageSwitch(path, message = "") {
    cookieUtility.setCookie(KEY_ApplicationPageSwitch__lockedpath, path);
    cookieUtility.setCookie(KEY_ApplicationPageSwitch__lockedpathmessage, message);
}

export function unlockApplicationPageSwitch() {
    cookieUtility.removeCookie(KEY_ApplicationPageSwitch__lockedpath);
    cookieUtility.removeCookie(KEY_ApplicationPageSwitch__lockedpathmessage);
}

export function getApplicationLockedPath() {
    return cookieUtility.getCookie(KEY_ApplicationPageSwitch__lockedpath);
}

export function getApplicationLockedPathMessage() {
    return cookieUtility.getCookie(KEY_ApplicationPageSwitch__lockedpathmessage);
}

let _outOfFlow = (function() {
    let last = route.APPLICATION_STATE_INFO_PAGE;

    return function(paths, to, resetLast) {
        if (resetLast === true) {
            last = route.APPLICATION_STATE_INFO_PAGE;
            return;
        }

        // Make sure 'to' path is after 'last' path
        let forward = paths.indexOf(last) <= paths.indexOf(to);

        // if we are trying to go forward AND the next path we are going towards is a "forward locking path", then set it as
        // the path to reset to if we ever go out of flow in the future
        last = forward && FORWARD_LOCKING_PATHS.indexOf(to) > -1 ? to : last;

        // If the path we are trying to go towards is *before* the path we were just at, then we are out of flow so return true
        if (paths.indexOf(last) > paths.indexOf(to)) {
            return true;
        }

        // All  is well, return false
        return false;
    };
})();

export const ApplicationPageSwitch = withRouter(
    (function() {
        // This variable keeps track of the last route that was successfully rendered
        let _memory = null;

        return function(props) {
            if (
                !props.isAuthenticated ||
                props.location.pathname.indexOf(route.APPLICATION_EXTERNAL_WELCOME_PAGE) > -1
            ) {
                // Check if the user isn't authenticated. Not sure why we need the second clause?

                // Basically, all of this (should) reset the user to the state info page
                _outOfFlow(null, null, true);
                unlockApplicationPageSwitch();
                return (_memory = props.children);
            }

            let _lockedpath = getApplicationLockedPath();

            if (_lockedpath === undefined) {
                // If their is no previously locked path

                // Determine which flow we are on
                let _flow = props.isOrganic ? APPLICATION_ORGANIC_FLOW_PAGES : APPLICATION_EXTERNAL_FLOW_PAGES;
                // Make sure the current path is in the chosen flow
                let _path = _flow.find(path => props.location.pathname.indexOf(path) > -1);

                if (_path === undefined) {
                    return props.children;
                }

                if (_outOfFlow(_flow, _path, false)) {
                    // If the user is out of flow

                    // Force url to go forward one page in the history, which should return them back to the page they just naviagted away from
                    props.history.go(1);

                    if (FORWARD_LOCKING_PATHS.indexOf(window.location.pathname) > -1) {
                        // If the path navigated to is a forward locking path

                        // Reset user back to stateinfo page
                        _outOfFlow(null, null, true);

                        return props.children;
                    } else {
                        return null;
                    }
                }
                // If curent path is a "lockable path" (a route that a user can only view once), then lock it (by storing it in a cookie)
                switch (_path) {
                    case route.APPLICATION_KNOWYOURCUSTOMER_PAGE:
                        let message = "Leaving this page will result in the cancellation of your application";
                        lockApplicationPageSwitch(props.location.pathname, message);
                        break;
                    case route.APPLICATION_REJECT:
                    case route.APPLICATION_REJECT_PAGE:
                    default:
                        unlockApplicationPageSwitch();
                }

                return (_memory = props.children);
            } else {
                // If their is a previously locked path

                if (_memory === null) {
                    // If their is no successfully rendered path stored in _memory

                    if (_lockedpath.indexOf(props.location.pathname) === -1) {
                        // If the current path is locked

                        // Redirect (via react-router) to the locked path
                        props.history.push(_lockedpath);

                        return null;
                    } else {
                        // Return the most recently successfully rendered path
                        return (_memory = props.children);
                    }
                } else {
                    // if their is something in _memory

                    if (_lockedpath.indexOf(props.location.pathname) === -1) {
                        // if the current location is not a locked path

                        // Redirect (via react-router) to the locked path
                        props.history.push(_lockedpath);

                        // Alert user that they are leaving the page
                        let message = getApplicationLockedPathMessage();
                        if (message) {
                            alert(message);
                        }
                    }
                    return _memory;
                }
            }
        };
    })()
);

/**
 * COMPONENTS
 -------------------------------------------------------------------------- */

export const XLink = withRouter(function({to, location, history, children, match, staticContext, ...rest}) {
    const href = history.createHref(createLocation(to, null, to, location));
    return (
        <a
            {...rest}
            href={href}
            onClick={e => {
                e.preventDefault();
                let _lockedpath = getApplicationLockedPath();
                if (_lockedpath) {
                    let message = getApplicationLockedPathMessage();
                    if (window.confirm(message)) {
                        unlockApplicationPageSwitch();
                        rest.onClick && rest.onClick();
                        history.push(to);
                        _outOfFlow(null, null, true);
                    }
                } else {
                    rest.onClick && rest.onClick();
                    history.push(to);
                    _outOfFlow(null, null, true);
                }
            }}
        >
            {children}
        </a>
    );
});

XLink.propTypes = {
    to: P.string.isRequired
};

export const ProtectedRoute = class extends React.Component {
    static propTypes = {
        path: P.string.isRequired,
        isAuthorized: P.any.isRequired,
        redirect: P.string.isRequired,
        component: P.any,
        render: P.func
    };

    render() {
        const {path, isAuthorized, redirect, component, render} = this.props;

        //Cast to React component
        if (isAuthorized) {
            let routeProps = {
                path
            };

            if (render) {
                routeProps.render = render;
            } else {
                routeProps.component = component;
            }

            return <Route {...routeProps} />;
        } else {
            return <Redirect to={redirect} />;
        }
    }
};

export const switchOrgOTPLogin = (orgId, profile, email) => {
    let urlParams = getUrlParameters(orgId);
    let url;
    if (profile !== "production") {
        let domain = window.location.hostname.split("-")[0];
        url =  domain === "localhost"
            ? `http://localhost:${urlParams.port}/login/otp/email`
            : `https://${domain}-${urlParams.secure}.${urlParams.appName}.com/login/otp/email`;
    } else {
        url = `https://${urlParams.secure}.${urlParams.appName}.com/login/otp/email`;
    }

    window.location.href = url + "?email=" + email;
}
