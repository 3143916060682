import React from "react";
import P from "prop-types";
import {connect} from "react-redux";
import LazyLoad from 'react-lazyload';
import * as constants from "../../shared/constant/constant";
import * as field from "../../shared/constant/field";
import gdLogo from "../../images/third-party/gd-logo.png";
import * as constant from "../../shared/constant/constant";
import * as utility from "../../shared/utility/utility";
import * as fields from "../../shared/constant/field";

class ForaCreditFooter extends React.Component {


    render() {
        let logo = "https://cdn.foracredit.ca/web/site/footer-logo.svg";
        if (this.props.orgId === constant.FORA_PLUS_ORGANIZATION_ID) {
            logo = utility.getResourceURL(this.props.resourceCDN, "koholineofcredit", "canada-koho-logo-footer.svg")
        }
        return (
            <React.Fragment>
                <div className="x-background_color_canada_footer wrapper p-3">
                    <div className="text-left fora-header">
                    <LazyLoad>
                        <img src={logo} alt="fora-logo" className="fora-footer-img" />
                    </LazyLoad>
                    </div>
                    <div className="footer-content pr-lg-3 footer-text-color">
                        <p>
                            <small><sup>1</sup>Not all applications are approved; duration of verification and approval
                                processes may vary. Credit limit may change and is subject to further criteria.</small>
                        </p>
                        <p>
                            <small><sup>2</sup>You can request a draw at any time, so long as you have available credit, your account is in good
                                standing, and you otherwise meet any criteria required to request draws from your account.</small>
                        </p>
                        <p>
                            <small><sup>3</sup>If approved, any requested funds will typically be deposited into your
                                bank account the same business day; timing of funding may vary. The date and time
                                the funds are made available to you by your bank are subject to your bank’s policies.
                                For specific funding cut-off times,
                                click <a href={constants.FORA_CUT_OFF_TIMES} target="_blank" rel="noopener noreferrer" className="footer-links-color">here.</a></small>
                        </p>
                    </div>

                    <div className="footer-sidebar">
                        <section className="p-3 pr-0 ml-lg-5">
                            <p className="footer-text-color text-center">
                                <b>
                                    <span className="material-symbols-rounded footer-question-mark">help</span>
                                    &nbsp; Questions? We're here to help.
                                </b>
                            </p>
                            <div className="text-center">
                                <button
                                    id="submit-login"
                                    type="submit"
                                    className="contact-us-button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open(this.props.content.common.contactUsURL, "_blank");
                                    }}
                                >
                                    <b>Contact Us</b>
                                </button>
                            </div>
                        </section>
                    </div>

                    <div className="temporary-hr-footer d-md-none">
                        <hr className="x-footer-hr"/>
                    </div>

                    <div className="footer">
                        <hr className="x-footer-hr"/>
                        <section className="text-sm-center text-lg-left footer-links-color">
                            <a
                                className="px-1 footer-links-color"
                                href={this.props.content.common.termsOfUseURL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <small>Terms of Use</small>
                            </a>
                            <span className="px-1">|</span>
                            <a
                                className="px-1 footer-links-color"
                                href={this.props.content.common.privacyPolicyURL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <small>Privacy Policy</small>
                            </a>
                            <span className="px-1">|</span>
                            <a
                                className="px-1 footer-links-color"
                                href={this.props.content.common.legalURL}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <small>Legal</small>
                            </a>
                        </section>

                        <p className="footer-text-color text-sm-center text-lg-left mb-0 pt-2">
                            <small>
                                PO Box 17071, Toronto, RPO Yonge-King, ON M5E 1Y2 | <br className="mobile-break"/>{(new Date()).getFullYear()} Fora Credit Inc.<sup>TM</sup>  | All rights reserved.
                            </small>
                        </p>
                        <div>
                            <LazyLoad>
                                <img src={gdLogo} alt="gd-logo" id="gd-logo-img"/>
                            </LazyLoad>
                        </div>
                        <p className="footer-bottom-height"></p>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ForaCreditFooter.propTypes = {
    tel: P.string,
};

const mapStateToProps = state => {
    return {
        tel: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.UI][field.ORGANIZATION.SUPPORT_NUMBER],
        resourceCDN: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.RESOURCE_CDN],
        resourceFolderName:
            state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.RESOURCE_FOLDERNAME],
        content: state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.ORG_CONTENT],
        orgId: state[fields.REDUCER_NODE_ORGANIZATION][fields.ORGANIZATION.CONFIG][fields.ORGANIZATION.ORGANIZATION_ID]
    };
};

ForaCreditFooter = connect(
    mapStateToProps
)(ForaCreditFooter);

export default ForaCreditFooter;
