import React from "react";
import P from "prop-types";
import {connect} from "react-redux";

import * as orgs from "../../shared/constant/constant";
import * as field from "../../shared/constant/field";

import Header from "./_common/Header";
import CreditfreshFooter from "./_CreditfreshFooter";
import CreditfreshUniversalFooter from "./_CreditfreshUniversalFooter";
import CreditfreshPlusFooter from "./_CreditfreshPlusFooter";
import ForaCreditFooter from "./_ForaCreditFooter";
import MoneykeyFooter from "./_MoneykeyFooter";
import MoneykeyCCBFooter from "./_MoneykeyCCBFooter";
import PathwardFooter from "./_PathwardFooter";
import * as utility from "../../shared/utility/utility";

class Layout extends React.Component {
    render() {
        let Footer;
        switch (this.props.organizationId) {
            case orgs.MONEYKEY_ORGANIZATION_ID:
                Footer = MoneykeyFooter;
                break;
            case orgs.CREDITFRESH_ORGANIZATION_ID:
                Footer = this.props.isAuthenticated ? CreditfreshFooter : CreditfreshUniversalFooter;
                break;
            case orgs.MONEYKEY_PLUS_ORGANIZATION_ID:
                Footer = MoneykeyCCBFooter;
                break;
            case orgs.CREDITFRESH_PLUS_ORGANIZATION_ID:
                Footer = this.props.isAuthenticated ? CreditfreshPlusFooter : CreditfreshUniversalFooter;
                break;
            case orgs.FORA_ORGANIZATION_ID:
                Footer = ForaCreditFooter;
                break;
            case orgs.PATHWARD_ORGANIZATION_ID:
                Footer = PathwardFooter;
                break;
            case orgs.FORA_PLUS_ORGANIZATION_ID:
                Footer = ForaCreditFooter;
                break;

            default:
                throw new Error("org not defined");
        }

        return (
            <div id="x-root" className={`x-root x-org-id--${utility.getOrganizationId()}`}>
                <div className="position-relative">
                    <header className="x-layout__header">
                        <Header />
                    </header>
                    <main className="x-layout__main mt-3 mb-4">{this.props.children}</main>
                    <footer className="overflow-auto">
                        <Footer />
                    </footer>
                </div>
            </div>
        );
    }
}

Layout.propTypes = {
    organizationId: P.number.isRequired
};

const mapStateToProps = state => ({
    organizationId:
        state[field.REDUCER_NODE_ORGANIZATION][field.ORGANIZATION.CONFIG][field.ORGANIZATION.ORGANIZATION_ID],
    isAuthenticated: state[field.REDUCER_NODE_AUTH][field.ISAUTHENTICATED]
});

const mapDispatchToProps = dispatch => ({});

Layout = connect(
    mapStateToProps,
    mapDispatchToProps
)(Layout);

export default Layout;
